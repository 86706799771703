import { FormValues, StepName } from '../../../components/Modals/ModalFlows/DocumentRequest/utils'
import { Product } from '../../../utils/types/Product'

type Option = {
  label: string
  value: string
}

interface RequestForDocsModel {
  formValues: FormValues
  product: Product
  updateValues: (key: StepName) => (values: FormValues[StepName]) => void
  goToNextStep: () => void
  onSubmit: () => void
  documentTypeOptions?: Option[]
}

class RequestForDocs {
  formValues: RequestForDocsModel['formValues']

  product: RequestForDocsModel['product']

  updateValues: RequestForDocsModel['updateValues']

  goToNextStep: RequestForDocsModel['goToNextStep']

  onSubmit: RequestForDocsModel['onSubmit']

  documentTypeOptions?: RequestForDocsModel['documentTypeOptions']

  constructor(model: RequestForDocsModel) {
    const {
      formValues,
      product,
      updateValues,
      goToNextStep,
      onSubmit,
      documentTypeOptions,
    } = model

    this.formValues = formValues
    this.product = product
    this.updateValues = updateValues
    this.goToNextStep = goToNextStep
    this.onSubmit = onSubmit
    this.documentTypeOptions = documentTypeOptions
  }
}

export default RequestForDocs
