import isEmpty from 'lodash/isEmpty'

import { CurrencyId, Product, ProductPrice } from '../../utils/types/Product'
import { isDefined } from '../../utils/types/misc'
import {
  CONSUMPTION_TAKE_RATE_TIERS,
  CountryData,
  DEFAULT_CURRENCY,
  PRODUCT_ORDER_PRICE_LIMIT,
  TaxCountryId,
  VATs,
} from '../../utils/constants'
import { Locale } from '../../external'
import { getPricePerPieceUnit, getTotalPieceCount } from '../salesUnit'

//  Internal functions related to prices.
//  Use the usePrice hook or getPrices getter to access discounts in components

export interface WithPrice {
  id: string
  price?: ProductPrice[]
}

export type CalculateTakeRate = (
  product: Product,
  selectedQuantity: number,
  excludeAmount?: number
) => {
  product: Product
  prices: ProductPrice[]
}

export const isDiscount = (price: ProductPrice): boolean => (
  price['price.label'].includes('bulk-discount')
)

export const isOldDiscount = (price: ProductPrice): boolean => (
  price['price.label'].includes('pallet-discount')
)

export const hasDiscounts = (prices: ProductPrice[]): boolean => (
  isDefined(prices.find(isDiscount))
)

const isZeroDiscount = (
  price: ProductPrice,
  defaultPriceValue: string | number | undefined,
): boolean => (
  isDiscount(price)
  && (
    price['price.value'] === defaultPriceValue
    || !Number(price['price.quantity'])
  )
)

const filterPricesListByCurrency = (prices: ProductPrice[], currency?: CurrencyId) => (
  prices.filter((price) => price['price.type'] === 'default' && (!currency || price['price.currencyid'] === currency))
)

const getDefaultPrice = (
  prices: ProductPrice[],
  currency?: CurrencyId,
): ProductPrice | undefined => (
  filterPricesListByCurrency(prices, currency)
    .find((price) => (!isDiscount(price) && !isOldDiscount(price)))
)

export const filterZeroDiscountPrices = (prices: ProductPrice[]): ProductPrice[] => (
  prices.filter((price) => (
    !isZeroDiscount(price, getDefaultPrice(prices, 'EUR')?.['price.value'])
  )))

const getPrice = (
  price: ProductPrice[] | undefined,
  priceType: 'default' | 'cost',
  currency: CurrencyId,
): ProductPrice | undefined => price?.find(
  (value) => value['price.type'] === priceType
      && !isDiscount(value)
      && !isOldDiscount(value)
      && value['price.currencyid'] === currency,
)

const dedupePricesByQuantityAndTime = (prices: ProductPrice[]) => Array.from(
  prices.reduce((priceMap, price) => {
    const existingPrice = priceMap.get(price['price.quantity'])
    const currentPriceModifiedDate = new Date(price['price.mtime']!)
    const existingPriceModifiedDate = new Date(existingPrice?.['price.mtime']!)
    if (!existingPrice || (currentPriceModifiedDate > existingPriceModifiedDate)) {
      priceMap.set(price['price.quantity'], price)
    }
    return priceMap
  }, new Map<number, ProductPrice>()).values(),
)

// Price object is the one with lowest quantity
const getProductPriceObject = (
  product?: Pick<Product, 'price'>,
  currency?: CurrencyId,
): ProductPrice | undefined => {
  const filteredByCurrency = filterPricesListByCurrency((product?.price || []), currency)
  return !isEmpty(filteredByCurrency)
    ? dedupePricesByQuantityAndTime(
      filteredByCurrency,
    ).sort((priceA, priceB) => priceA['price.quantity'] - priceB['price.quantity'])[0]
    : undefined
}

const getProductPrice = (product?: Pick<Product, 'price'>, currency?: CurrencyId) => Number(getProductPriceObject(product, currency)?.['price.value']) ?? 1

const getReferencePrice = (product: Product, productPrice?: number | string): number => {
  const totalPieceCount = getTotalPieceCount(product)
  const count = getPricePerPieceUnit(product) === '100 pcs' ? totalPieceCount / 100 : totalPieceCount
  const defaultProductPrice = getProductPriceObject(product)?.['price.value'] ?? NaN
  return Number(productPrice ?? defaultProductPrice) / count
}

const getCustomPrices = (product: Product) => product.price.filter((price) => price['price.label'].includes('custom_discount'))

export const getSellingPriceValue = (costPrice: number | string, marginValue: number | string) => (
  Number(costPrice) / (1 - (Number(marginValue) / 100))
)

export const getMarginRounded = (costPrice: number, sellingPrice: number): number => (
  Number((((sellingPrice - costPrice) * 100) / sellingPrice).toFixed(2))
)

export const getNewResourcePrice = (priceType: 'default' | 'cost', currency: CurrencyId): ProductPrice => ({
  id: '',
  'price.taxrate': 0,
  'price.value': 0,
  'price.type': priceType,
  'price.quantity': 1,
  'price.label': `Default ${priceType === 'cost' ? priceType : ''}`,
  'price.currencyid': currency,
  'price.costs': 0,
  'price.domain': 'product',
})

// Same formula as in BE
const getTakeRatePercentage = (
  tier: number,
  config: Record<number, number> = CONSUMPTION_TAKE_RATE_TIERS,
) => {
  const minPercentage = Math.min(...Object.values(config))
  const maxTier = Math.max(...Object.keys(config).map(Number))

  const [currentTier, currentPercentage] = Object.entries(config)
    .filter(([value]) => Number(value) <= tier).reverse()[0] || []
  const [nextTier, nextPercentage] = Object.entries(config)
    .filter(([value]) => Number(value) > tier)[0] || [] // Next can be empty if max tier reached

  if (tier > maxTier) {
    return minPercentage
  }
  if (!nextTier) {
    return currentPercentage
  }
  return Math.round((currentPercentage
    + ((tier - Number(currentTier)) * (Number(nextPercentage) - currentPercentage))
    / (Number(nextTier) - Number(currentTier))) * 1e4) / 1e4
}

export const getTaxRate = (country: Locale | null) => {
  const countryId = CountryData.find((ele) => ele.locale === country)?.locale as TaxCountryId
  return VATs[countryId ?? 'fi']
}
export const isOrderPriceLimitReached = (totalPrice: number, product: Product) => {
  const productPriceObj = getProductPriceObject(product)
  const currencyId = productPriceObj?.['price.currencyid'] || DEFAULT_CURRENCY

  return totalPrice >= PRODUCT_ORDER_PRICE_LIMIT[currencyId]
}

const getProductMoq = (product?: Product) => getProductPriceObject(product)?.['price.quantity'] ?? 1

const getProductMov = (product: Product) => getProductMoq(product) * (Number(getDefaultPrice(product.price)?.['price.value']) ?? 1)

export const libraryFunctions = {
  getProductPriceObject,
  getDefaultPrice,
  getProductPrice,
  getPrice,
  getReferencePrice,
  getCustomPrices,
  getTakeRatePercentage,
  getProductMoq,
  getProductMov,
}
