import TagManager from 'react-gtm-module'

import { Locale } from '../../external'
import { formatCategoriesForAnalytics } from '../../lib/category'
import { ActiveCountryId } from '../constants'
import { isProductArray, SearchResult, SearchResults } from '../types/search'
import { getBrandName, getProductName } from '../../lib/text'
import { getCurrencyCode } from '../locales'
import { getPrices } from '../../lib/getPrices'

export interface SearchEventParams {
  searchTerm: string
  locale: Locale
  country: ActiveCountryId
  searchResults: SearchResults
  resultQuantity: number
}

const searchEvent = (searchEventParams: SearchEventParams) => {
  const {
    searchTerm,
    locale,
    country,
    searchResults,
    resultQuantity,
  } = searchEventParams

  // Abort when null, important when switching between catalog views (searchResults inits as null)
  if (searchResults === null) {
    return
  }

  const formattedResults: SearchResult[] = isProductArray(searchResults)
    ? searchResults.map((product) => {
      const brand = getBrandName(product)
      const itemName = getProductName(product)
      const categories = formatCategoriesForAnalytics(product)
      const currency = getCurrencyCode(country)
      const { price } = getPrices(product, 1).final

      return {
        item_id: product['product.code'],
        item_name: itemName ?? product['product.label'],
        price,
        currency,
        item_brand: brand ?? `${product.supplier[0]['supplier.label']} (fallback, supplier name)`,
        supplier: product.supplier[0]['supplier.label'],
        ...categories,
      }
    })
    : searchResults

  TagManager.dataLayer({
    dataLayer: {
      event: 'search',
      language: locale,
      country,
      search_term: searchTerm,
      search_result: formattedResults,
      result_quantity: resultQuantity,
    },
  })
}

export default searchEvent
