import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter'

export const defaultConfig = {
  server: {
    timeoutSeconds: 10, // Increase request timeout to 10 seconds
    apiKey: process.env.NEXT_PUBLIC_TYPESENSE_KEY || '', // Be sure to use an API key that only allows search operations
    nodes: [
      {
        host: process.env.NEXT_PUBLIC_TYPESENSE_HOST || '',
        port: 443,
        protocol: 'https',
      },
    ],
    // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
    cacheSearchResultsForSeconds: 2 * 60,
  },
  collectionSpecificSearchParameters: {
    products: {
      preset: 'default_products_en',
      limit: 52,
    },
    suppliers: {
      preset: 'default_suppliers',
      limit: 3,
    },
  },
}

// Return 52 hits, but only show x amount in search popup
export const HIT_COUNT_POPUP = 6

export const typesenseInstantSearchAdapter = new TypesenseInstantSearchAdapter(defaultConfig)
