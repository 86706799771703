import RequestForQuote from './RequestForQuote'
import RequestForSample from './RequestForSample'
import SingleProduct from './SingleProduct'
import Customer from './Customer'
import Auth from './Auth'
import RequestForDocs from './RequestForDocs'

export type AppModels = {
  Auth: Auth
  Customer: Customer
  SingleProduct: SingleProduct
  RequestForQuote: RequestForQuote
  RequestForSample: RequestForSample
  RequestForDocs: RequestForDocs
}

export default {
  Auth,
  Customer,
  SingleProduct,
  RequestForQuote,
  RequestForSample,
  RequestForDocs,
}
