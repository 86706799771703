import TagManager from 'react-gtm-module'

interface ViewCustomerTypeModal {
  context: string
}

const selectCustomerTypeEvent = ({ context }: ViewCustomerTypeModal) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'select_customer_type',
      context,
    },
  })
}

export default selectCustomerTypeEvent
