import { OrderListItem, OrderListItemProduct } from './types/buyerCatalogs'
import { getPrices } from '../lib/getPrices'

const filterOutUnavailableCatalogProducts = (
  orderList: OrderListItem[],
): OrderListItemProduct[] => orderList.filter((item) => {
  if (!item.product) {
    return false
  }
  return !!getPrices(item.product, 1).default.price
}) as OrderListItemProduct[] // Cast to narrowed type after filtering

export default filterOutUnavailableCatalogProducts
