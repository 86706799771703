import { JSXElementConstructor, ReactElement, FC, createRef } from 'react'
import { Grid, Popover, Row, Whisper } from 'rsuite'
import { OverlayTriggerInstance } from 'rsuite/esm/Picker'
import useTranslation from 'next-translate/useTranslation'

import { generateRouterUrlObject } from '../../../../utils/urls'
import { Catalog } from '../../../../utils/types/Product'
import { CatalogBaseIncludes, HOVER_DELAY } from '../../../../utils/constants'
import { useApi } from '../../../../services/useApi'
import { MegaMenuFormattedData } from '../../../../utils/types/strapi'
import useCategoryDataStructure from '../../../hooks/useCategoryDataStructure'
import MegaMenuLinksColumn from './MegaMenuLinksColumn'
import MegaMenuCtaColumn from './MegaMenuCtaColumn'
import MegaMenuLink from './MegaMenuLink'

import styles from '../../../../styles/MegaMenu.module.less'

interface TopCategoryData {
  id: string
  attributes: {
    'catalog.code': string
    'catalog.label': string
  }
}

interface MegaMenuProps {
  // Explicitly type children since Whisper does not simply accepts ReactNode for some reason
  children: ReactElement<any, string | JSXElementConstructor<any>>
  topCatData: TopCategoryData
  columnData: MegaMenuFormattedData
  headerRef: React.RefObject<HTMLDivElement>
}

const MegaMenu: FC<MegaMenuProps> = (props) => {
  const { children, topCatData, columnData, headerRef } = props

  const { t } = useTranslation('url')
  const { getResource, getResourceCount } = useApi()
  const { selectedCategory } = useCategoryDataStructure()

  const triggerRef = createRef<OverlayTriggerInstance>()
  const closeMegaMenu = () => triggerRef?.current?.close()

  const { attributes: parentCatAttributes, id: topCategoryId } = topCatData
  const { popularBrandsColumn, promotionalCollectionsColumn, ctaColumn } = columnData || {}

  const { data: categoriesResp, isLoading: isSubCategoryDataLoading } = getResource<Catalog>(
    topCategoryId ? 'catalog' : null,
    `include=${CatalogBaseIncludes.join(',')}&id=${topCategoryId}&fields[catalog.lists]`,
  )

  const subCategories = categoriesResp[0]?.catalog || []

  const productsHostUrl = `/${t('products')}`
  const SupplierHostUrl = `/${t('supplier')}`

  // Get product counts for categories
  const { data: productCounts } = getResourceCount('product', 'aggregate=index.catalog.id')

  const categoryProductCounts = productCounts?.reduce<Record<string, number>>((acc, count) => {
    acc[count.id] = count.attributes
    return acc
  }, {})

  // Remove subcategories that have no products or no entry in categoryProductCounts
  const subcategoriesWithProducts = (subCategories || []).filter(
    (subCategory) => subCategory.id in (categoryProductCounts || {}),
  )

  return (
    <Whisper
      ref={triggerRef}
      enterable
      placement="bottom"
      container={headerRef?.current ? headerRef.current : undefined}
      delayOpen={HOVER_DELAY}
      speaker={
        (
          <Popover
            arrow={false}
            className={styles['megamenu-popover-container']}
            data-testid="megamenu-popover"
          >
            <Grid
              fluid
              className="padding-top-spacer-quadruple padding-bottom-spacer-quadruple"
            >
              <Row className="max-width-lg padding-left-spacer-double padding-right-spacer-double">
                <MegaMenuLinksColumn
                  isLoadingData={isSubCategoryDataLoading}
                  heading={topCatData.attributes['catalog.label']}
                >
                  {subcategoriesWithProducts?.map((subCategory) => (
                    <MegaMenuLink
                      key={subCategory.id}
                      label={subCategory['catalog.label']}
                      linkRedirectObjectData={generateRouterUrlObject(
                        `/category/${parentCatAttributes['catalog.code']}/${subCategory['catalog.code']}`,
                        productsHostUrl,
                      )}
                      additionalClassNames={selectedCategory === subCategory['catalog.code'] ? styles.active : ''}
                      externalCallback={closeMegaMenu}
                    />
                  ))}
                </MegaMenuLinksColumn>
                <MegaMenuLinksColumn
                  isLoadingData={!popularBrandsColumn}
                  heading={popularBrandsColumn?.header}
                >
                  {popularBrandsColumn?.linkData.map(({ id, label, link }) => (
                    <MegaMenuLink
                      key={id}
                      label={label}
                      linkRedirectObjectData={generateRouterUrlObject(link, SupplierHostUrl)}
                      externalCallback={closeMegaMenu}
                    />
                  ))}
                </MegaMenuLinksColumn>
                <MegaMenuLinksColumn
                  isLoadingData={!promotionalCollectionsColumn}
                  heading={promotionalCollectionsColumn?.header}
                >
                  {promotionalCollectionsColumn?.linkData.map(({ id, label, link, params }) => (
                    <MegaMenuLink
                      key={id}
                      label={label}
                      linkRedirectObjectData={
                        generateRouterUrlObject(link, productsHostUrl, params)
                      }
                      externalCallback={closeMegaMenu}
                    />
                  ))}
                </MegaMenuLinksColumn>
                <MegaMenuCtaColumn ctaColumnData={ctaColumn} />
              </Row>
            </Grid>
          </Popover>
          )
        }
    >
      {children}
    </Whisper>
  )
}

export default MegaMenu
