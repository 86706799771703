import { Translate } from 'next-translate'
import sortBy from 'lodash/sortBy'

import { CountryAndLocale, Locale } from '../external'
import { ActiveCountryId, CountryData, Currency, DEFAULT_CURRENCY, DEFAULT_TAX_RATE_PERCENTAGE } from '../utils/constants'
import { formatPriceNumber } from '../utils/math'
import {
  CartSupplierProducts,
  PaymentData,
  SupplierMappedProducts,
  SupplierOrderData,
} from '../utils/types/Cart'
import { isDefined } from '../utils/types/misc'
import { CurrencySymbol, Product, Supplier } from '../utils/types/Product'
import { capitalizeFirstLetter, removeDuplicates } from '../utils/util'
import { getExactStockLevel, getIsImportedSupplier, getPathName, getProductManualStockLimit } from './product'
import { getTaxRate } from './resources/price'
import { getSalesUnit } from './salesUnit'
import {
  getSupplierDeliveryDetails,
  getOrderServiceAttriutes,
  isFreeDelivery,
} from './service'
import { getProductName } from './text'
import { getVariantType } from './variant'
import { getBasePathWithLocale } from '../utils/urls'
import { getCountryAndLocaleStrings } from '../utils/locales'
import {
  getOrderAddressObject,
  getOrderPaymentData,
  getOrderSupplierGroupByProducts,
} from './OrderHistory'
import {
  CartAddress,
  CartOrderResponse,
  CartProduct, CartResponse, OrderAddressInclude,
  OrderAttributes,
  OrderInclude,
  OrderProductInclude,
  OrderType,
  SingleOrderResponse,
  WithSuppliers,
} from '../utils/types/Order'
import {
  isAddressInclude,
  isProductInclude,
  isCartResource,
  isServiceInclude,
  getIncluded,
  isCartRelationships,
  getProductIncluded,
  isSupplierMappedResource,
  isOrderResource,
} from '../utils/types/guards/Order'
import {
  selectCartAddress,
  selectSupplierProductsTotal,
  selectSupplierShipping,
  selectSupplierTotal,
  selectSupplierBulkDiscount,
  selectSupplierDiscount,
} from '../components/Cart/selectors'
import { CustomerResponse } from '../utils/types/customer'
import { selectNormalizedCustomer, selectOrderAddressData } from '../components/BuyerProfile/Account/selectors'
import { OrderConfirmationSupplier } from '../utils/types/orderConfirmation'
import { Url } from './urls'
import { getPrices } from './getPrices'

export const isValidCartProduct = (product: OrderProductInclude) => {
  const allProductVariants = product.product?.product?.map((ele) => ele['product.label'].toLowerCase())
  const validTypes = ['variant', 'size', 'color']
  const includedProductVariant = product.attributes.attribute
    ?.map((ele) => (
      validTypes.includes(ele['order.product.attribute.type'])
        ? ele['order.product.attribute.name'].toLowerCase()
        : null
    ))
    .filter((ele) => ele !== null)

  const isVariantValid = allProductVariants?.some(
    (item) => includedProductVariant?.includes(item),
  )

  const productHasVariants = allProductVariants && allProductVariants.length !== 0
  const includedProductHasVariant = Boolean(
    includedProductVariant && includedProductVariant.length !== 0,
  )

  return {
    productHasVariants,
    includedProductHasVariant,
    isVariantValid,
  }
}

export const getSupplierGroupByProducts = (
  cart: WithSuppliers<SingleOrderResponse>,
): CartSupplierProducts => {
  const filteredIncludedProducts = getProductIncluded(cart) || []

  const isProductMatchCartSupplier = (cartItem: OrderProductInclude, supplierCode: string) => (
    cartItem.product?.supplier[0]['supplier.code'] === supplierCode
  )

  const underMoqProductList: OrderProductInclude[] = []
  const unavailableProductList: OrderProductInclude[] = []

  const totalQuantityList = filteredIncludedProducts
    .reduce<Record<string, number>>((prev, { attributes, product }) => {
    const sku = product?.['product.code']
    if (!sku) return prev

    const quantity = attributes['order.product.quantity']
    const prevQuantity = prev[sku] || 0

    return { ...prev, [sku]: quantity + prevQuantity }
  }, {})

  const filteredProductsUnderMOQ = filteredIncludedProducts
    ?.filter((product) => {
      if (!product.product) { // Product is not available
        unavailableProductList.push(product)
        return false
      }

      // TODO: Add variant validation when id is correctly added to the attriutes

      const sku = product.product['product.code']
      const totalProductQuantity = totalQuantityList[sku]
      const { moq } = getPrices(product.product, 1)

      if (totalProductQuantity < moq) {
        underMoqProductList.push(product)
        return false
      }

      return true
    })

  const suppliers = removeDuplicates(
    filteredProductsUnderMOQ?.map(
      (item) => item.product?.supplier[0]['supplier.code'],
    ) ?? [],
  )

  const supplierMappedProducts = suppliers.map((supplierCode) => ({
    supplierCode,
    supplierInfo: cart.suppliers?.find((supplier) => supplier['supplier.code'] === supplierCode),
    totalProductCount: filteredProductsUnderMOQ?.filter(
      (cartItem) => isProductMatchCartSupplier(cartItem, supplierCode),
    ).reduce((total, item) => total + item.attributes['order.product.quantity'], 0) || 0,
    products: filteredProductsUnderMOQ?.filter(
      (cartItem) => isProductMatchCartSupplier(cartItem, supplierCode),
    ).filter((thing, index, self) => index === self.findIndex((task) => (
      task.product?.['product.id'] === thing.product?.['product.id']
    ))),
  }))

  return {
    ...cart,
    supplierMappedProducts: sortBy(supplierMappedProducts, (
      supp,
    ) => getIsImportedSupplier(supp.products)),
    underMoqProductList,
    unavailableProductList,
  }
}

export const getSupplierProducts = (
  resource: WithSuppliers<SingleOrderResponse>,
) => (
  getOrderSupplierGroupByProducts(resource)
)

export const getCartPaymentData = (cart: CartResponse): PaymentData => {
  const payment = getIncluded(cart)
    ?.filter(isServiceInclude)
    ?.find((include) => include.type === 'basket.service' && include.id === 'payment')
  const reference = cart.data?.attributes?.['order.customerref'] || ''
  const orderServiceAttributes = getOrderServiceAttriutes(payment!)

  return {
    reference,
    ...orderServiceAttributes,
  }
}

export const getPaymentData = (
  resource: SingleOrderResponse,
) => {
  const { relationships } = resource.data
  const serviceRelationships = isCartRelationships(relationships)
    ? relationships['basket.service']
    : relationships['order/service']
  return getOrderPaymentData(resource, serviceRelationships)
}

export const breakShareCartUrlParam = (skuAndQuantityParam: string) => {
  const splitParamData = skuAndQuantityParam.split(':') // splitParamData could be [SKU123, 251, 5] or [SKU123, 5] if not a variant
  const skuCode = splitParamData[0]
  const variantId = splitParamData.length === 3 && splitParamData[1] ? splitParamData[1] : ''
  const quantity = splitParamData.length === 3 ? splitParamData[2] : splitParamData[1]

  return {
    skuCode,
    variantId,
    quantity,
  }
}

export const generateSharedCartAttributes = (
  productsList: Product[],
  skuAndQuantityData: string[],
  suppliers: Supplier[],
  currentCountry: Locale | null,
  locale: string | undefined,
): OrderAttributes => {
  const getTotalProductPrice = (product: Product) => {
    // This takes into account the possibility of multiple SKU variants, so we need
    // to aggregate the quantity of all possible products under the same common SKU
    const totalQuantity = skuAndQuantityData.reduce(
      (aggregatedQuantity, skuAndQuantityParam) => {
        const { skuCode, quantity } = breakShareCartUrlParam(skuAndQuantityParam)
        if (skuCode === product?.['product.code']) {
          return aggregatedQuantity + Number(quantity)
        }
        return aggregatedQuantity
      }, 0,
    )
    const {
      final: { total: totalProductPrice },
    } = getPrices(product, totalQuantity)

    return totalProductPrice
  }

  const totalCartPrice = productsList.reduce(
    (total, currentProd) => total + getTotalProductPrice(currentProd), 0,
  )

  // Calculate the shipping costs from each supplier, if the total price of the supplier products
  // exceeds the set shipping limit
  const calculatedCountryShippingCost = suppliers?.reduce(
    (totalShippingCosts, supplier) => {
      const supplierProducts = productsList.filter((prodData) => prodData.supplier[0]['supplier.code'] === supplier['supplier.code'])

      if (!supplierProducts.length) {
        return totalShippingCosts
      }

      const { deliveryCost } = getSupplierDeliveryDetails(supplier, currentCountry)

      // Calculate the total price of all the products for the filtered supplier
      const supplierProductsPrice = supplierProducts.reduce(
        (totalPrice, product) => totalPrice + getTotalProductPrice(product), 0,
      )

      // Check if at least one of the products do not qualify for a free delivery
      const shouldApplyDeliveryFee = supplierProducts.some(
        (product) => (
          !isFreeDelivery(product, currentCountry, supplierProductsPrice)
        ),
      )

      const currentShippingCost = shouldApplyDeliveryFee ? Number(deliveryCost) : 0

      return totalShippingCosts + currentShippingCost
    }, 0,
  )
  const taxRate = getTaxRate(currentCountry)
  const taxRatePercentage = (taxRate / 100)
  const calculatedTaxValue = (
    (totalCartPrice + calculatedCountryShippingCost)
    * (taxRatePercentage || DEFAULT_TAX_RATE_PERCENTAGE)
  )

  const selectedCountryCurrency = CountryData.find(
    (country) => country.locale === currentCountry,
  )?.currency

  return {
    'order.id': null,
    'order.sitecode': '',
    'order.customerid': '',
    'order.languageid': locale as Locale || 'fi',
    'order.currencyid': selectedCountryCurrency || 'EUR',
    'order.price': totalCartPrice.toFixed(2),
    'order.costs': calculatedCountryShippingCost.toFixed(2),
    'order.rebate': '0.00',
    'order.taxvalue': calculatedTaxValue.toFixed(3),
    'order.taxflag': false,
    'order.customerref': '',
    'order.comment': '',
    'order.parentid': '',
  }
}

export const generateSharedCartIncludedArray = (
  productsList: Product[],
  skuAndQuantityDataList: string[],
  country: Locale | null,
): OrderProductInclude<'basket'>[] => skuAndQuantityDataList.map((skuAndQuantityData, index) => {
  const { skuCode, quantity, variantId } = breakShareCartUrlParam(skuAndQuantityData)
  const productData = productsList.find((product) => product['product.code'] === skuCode)

  // Manually get full price object to copy all data points
  const productPriceData = productData?.price.sort((priceA, priceB) => priceA['price.quantity'] - priceB['price.quantity'])?.[0]
  const productStockData = productData?.stock?.find((stockObj) => stockObj['stock.type'] === 'default')
  const productTextData = getProductName(productData)
  const productSupplierData = productData?.supplier[0]
  const productMedia = productData?.media?.[0]
  const numberQuantity = Number(quantity)
  const taxRate = getTaxRate(country)
  const relevantVariantAttribute = productData?.product?.find(
    (variant) => variant.attribute[0]?.['attribute.id'] === variantId,
  )?.attribute[0]

  const attributes = {
    // pass the attribute property only if the variant part is available on the SKU
    ...((variantId && relevantVariantAttribute) && {
      attribute: [
        {
          'order.product.attribute.id': variantId,
          'order.product.attribute.type': relevantVariantAttribute?.['attribute.type'],
          'order.product.attribute.code': relevantVariantAttribute?.['attribute.code'],
          'order.product.attribute.name': relevantVariantAttribute?.['attribute.label'],
          'order.product.attribute.value': relevantVariantAttribute?.['attribute.label'],
          'order.product.attribute.quantity': 1,
        },
      ],
    }),
    'order.product.id': productData?.['product.id'] ?? '',
    'order.product.price': String(productPriceData?.['price.value']),
    'order.product.costs': String(productPriceData?.['price.costs']),
    'order.product.rebate': productPriceData?.['price.rebate'] ?? '',
    'order.product.taxrate': taxRate ? String(taxRate) : '',
    'order.product.taxrates': {
      '': taxRate ? String(taxRate) : '',
    },
    'order.product.type': productData?.['product.type'] ?? '',
    'order.product.stocktype': productStockData?.['stock.type'] ?? '',
    'order.product.prodcode': skuCode,
    'order.product.productid': productData?.id ?? '',
    'order.product.supplierid': productSupplierData?.id ?? '',
    'order.product.vendor': productSupplierData?.['supplier.code'] || '',
    'order.product.parentproductid': '',
    'order.product.suppliername': productSupplierData?.['supplier.label'] ?? '',
    'order.product.qtyopen': numberQuantity,
    'order.product.quantity': numberQuantity,
    'order.product.name': productTextData || '',
    'order.product.description': '',
    'order.product.mediaurl': productMedia?.['media.url'] ?? '',
    'order.product.timeframe': productStockData?.['stock.timeframe'] ?? '',
    'order.product.position': null,
    'order.product.status': productData?.['product.status'] ?? 1,
    'order.product.notes': '',
  }

  return {
    id: index.toString(),
    product: productData,
    type: 'basket.product',
    links: { self: { allow: [], href: '' } },
    attributes,
  }
})

export const getRemainingMovCount = (
  supplierTotal: number | undefined,
  mov: number,
): number => (supplierTotal && mov > supplierTotal ? mov - supplierTotal : 0)

export const getIsUnderMov = (
  supplierTotal: number | undefined,
  mov: number,
) => (supplierTotal || 0) < mov

export const getSupplierMovData = (
  supplierCode: string,
  country: ActiveCountryId,
  modifiedCart: CartSupplierProducts | undefined | null,
) => {
  const cartSupplier = modifiedCart?.supplierMappedProducts.find(
    (supp) => supp.supplierCode === supplierCode,
  )

  const { minimumOrderValue } = getSupplierDeliveryDetails(cartSupplier?.supplierInfo, country)
  const supplierTotal = modifiedCart && cartSupplier
    ? selectSupplierTotal(modifiedCart, cartSupplier) || 0
    : 0
  const remainingMov = getRemainingMovCount(supplierTotal, minimumOrderValue)

  return {
    mov: minimumOrderValue,
    supplierTotal,
    remainingMov,
    isUnderMov: getIsUnderMov(supplierTotal, minimumOrderValue),
  }
}

export const filterUnderMovSuppliers = (
  modifiedCart: CartSupplierProducts | null,
  country: ActiveCountryId,
) => modifiedCart?.supplierMappedProducts.filter((supplier) => {
  const { isUnderMov } = getSupplierMovData(
    supplier.supplierCode,
    country,
    modifiedCart,
  )
  return !isUnderMov
})

export const getModifiedCartTotalDiscount = (
  modifiedCart: CartSupplierProducts,
) => {
  let discount = 0
  modifiedCart.supplierMappedProducts
    .forEach((supplier) => supplier.products
      ?.filter(isProductInclude)
      ?.forEach(({ product }) => {
        if (!product) return

        const totalProductQuantity = getIncluded(modifiedCart)
          ?.filter(isProductInclude)
          ?.filter((item) => item.product?.['product.id'] === product['product.id'])
          .reduce((total, current) => total + current.attributes['order.product.quantity'], 0)

        const {
          default: { total: totalDefaultPrice },
          final: { total: totalPrice },
        } = getPrices(product, totalProductQuantity)

        if (totalDefaultPrice !== totalPrice) {
          discount += totalDefaultPrice - totalPrice
        }
      }))

  return discount
}

export const getSupplierOrderData = (
  suppliers: SupplierMappedProducts[],
  cart: SingleOrderResponse,
  country: Locale,
  config: {
    shouldReturnUnavailableSuppliers?: boolean
  } = {},
): SupplierOrderData[] => {
  const {
    shouldReturnUnavailableSuppliers = false,
  } = config
  return suppliers
    .filter((supplier) => isDefined(supplier.supplierInfo) || shouldReturnUnavailableSuppliers)
    .map((supplier) => {
      const supplierShipping = selectSupplierShipping(cart, supplier)
      const total = selectSupplierProductsTotal(cart, supplier)
      const bulkDiscount = selectSupplierBulkDiscount(cart, supplier)
      const consumptionDiscount = selectSupplierDiscount(cart, supplier)
      const { minimumOrderValue: mov } = getSupplierDeliveryDetails(supplier?.supplierInfo, country)
      const isImportedSupplier = supplier.products?.[0]?.product
        ? getIsImportedSupplier(supplier.products)
        : false

      return {
        supplierName: supplier.supplierInfo?.['supplier.label'] || supplier.supplierCode || '',
        supplierCode: supplier.supplierInfo?.['supplier.code'] || supplier.supplierCode || '',
        supplierShipping,
        bulkDiscount,
        consumptionDiscount,
        total,
        mov,
        isImportedSupplier,
      }
    })
}

// to get all the variants of the product from included array and their's respective quantity
const getVariantProductsData = (
  included: OrderInclude[] | undefined,
  productId: string | undefined,
) => {
  const addedProducts = included
    ?.filter(isProductInclude)
    .filter(
      (prod) => (
        (prod?.product?.['product.id']
        || prod.attributes['order.product.parentproductid']
        || prod.attributes['order.product.productid']) === productId
      ),
    )
  const totalQuantity = addedProducts?.reduce(
    (total, current) => total + current.attributes['order.product.quantity'],
    0,
  ) ?? 1
  return { addedProducts, totalQuantity }
}

const getTotalProductPrice = (
  included: OrderInclude[] | undefined,
  product: Pick<Product, 'product.id'> | undefined,
  cartProduct: boolean,
): number => {
  const { addedProducts } = getVariantProductsData(included, product?.['product.id'])
  return addedProducts?.map(
    (prod) => {
      if (!prod.product) {
        return Number(prod.attributes['order.product.price'])
        * Number(prod.attributes['order.product.quantity'])
      }
      const price = cartProduct ? prod.attributes['order.product.price'] : getPrices(prod.product, 1).default.price
      return Number(price)
        * Number(prod.attributes['order.product.quantity'])
    },
  )?.reduce((total, current) => Number(total) + Number(current), 0) ?? 0
}

// Calculate the total discount of the whole order
export const getTotalDiscount = (
  suppliers: SupplierMappedProducts[],
  cart: SingleOrderResponse,
): number => {
  const cartDataAttributes = cart.data?.attributes
  return parseFloat(String(cartDataAttributes?.['order.rebate'])) || 0
}

// Check if product or any of its variant exceeds manual stock limit
const getIsProductOverManualStockLimit = (
  product: Product | undefined,
  included: OrderInclude[] | undefined,
  cartProductData: CartProduct,
): boolean => {
  const { totalQuantity } = getVariantProductsData(included, product?.['product.id'])

  return (product && totalQuantity > Number(getProductManualStockLimit(product)))
    || !!(product?.product?.some((variant: Product) => cartProductData['order.product.quantity']
      > Number(getProductManualStockLimit(variant))))
}

// Check if product or any of its variants exceeds exact stock limit
const getIsProductOverExactStockLimit = (
  product: Product | undefined,
  cartProductData: CartProduct,
): boolean => !!(product?.product?.some((variant: Product) => getExactStockLevel(variant) !== null && cartProductData['order.product.quantity']
      > Number(getExactStockLevel(variant))))

// Data needed for order confirmation section on summary page
export const getOrderConfirmationProducts = (
  suppliers: SupplierMappedProducts[],
  included: OrderInclude[] | undefined,
  currency: CurrencySymbol,
  t: Translate,
  config: {
    shouldReturnUnavailableSuppliers?: boolean
  } = {},
): OrderConfirmationSupplier[] => {
  const {
    shouldReturnUnavailableSuppliers = false,
  } = config
  return suppliers.map(({ supplierInfo, supplierCode, products }) => ({
    supplierInfo: shouldReturnUnavailableSuppliers
      ? supplierInfo || { 'supplier.code': supplierCode, 'supplier.label': supplierCode, id: '', product: [] }
      : supplierInfo,
    isImportedSupplier: getIsImportedSupplier(products),
    products: products
      ?.map(({ product, attributes }) => {
        // Coalesce over ids to get main product for variants, otherwise default id
        const productIdObject = product
          ?? { 'product.id': attributes['order.product.parentproductid'] || attributes['order.product.productid'] }
        const {
          addedProducts,
          totalQuantity,
        } = getVariantProductsData(included, productIdObject['product.id'])
        const isOverManualStockLimit = getIsProductOverManualStockLimit(
          product, included, attributes,
        )
        const {
          default: { price: defaultPrice = 1 },
          discount: { current: currentDiscount = undefined },
        } = product ? getPrices(product, totalQuantity) : { default: {}, discount: {} }
        const isOverExactStockLimit = getIsProductOverExactStockLimit(product, attributes)
        const totalPrice = getTotalProductPrice(included, productIdObject, true)
        const productSalesUnit = t(`products:${product ? getSalesUnit(product) : 'piece'}`, { count: 1 })
        const finalProductPrice = attributes['order.product.price'] // Discount included
        const nonDiscountedPriceWithSalesUnit = currentDiscount
          ? `${formatPriceNumber(defaultPrice)} ${currency} / ${productSalesUnit}`
          : null
        const discountDifference = currentDiscount
          ? formatPriceNumber(defaultPrice - Number(finalProductPrice))
          : null
        // If product is unavailable, the main SKU is not available - in that case,
        // use the first variant
        const productCode = product ? product['product.code'] : attributes['order.product.prodcode']

        return ({
          ...product,
          productCode,
          isOverManualStockLimit,
          isOverExactStockLimit,
          totalPrice: `${formatPriceNumber(totalPrice)} ${currency}`,
          productName: getProductName(product) || attributes['order.product.name'] || '',
          priceWithSalesUnit: `${formatPriceNumber(attributes['order.product.price'])} ${currency} / ${productSalesUnit}`,
          nonDiscountedPriceWithSalesUnit,
          discountDifference,
          addedProducts: addedProducts?.map((item) => {
            const variantQuantity = `${item.attributes['order.product.quantity']} ${t(`products:${t(product ? getSalesUnit(product) : 'piece')}`,
              {
                count: Number(
                  item.attributes['order.product.quantity'],
                ),
              })}`
            const variantLabel = !item?.attributes?.attribute
              ? ''
              : `${t(capitalizeFirstLetter(
                // selecting 'size' as a default variant type for older products
                product ? getVariantType(product.product) ?? 'size' : 'size',
              ))} ${item?.attributes?.attribute?.[0]?.['order.product.attribute.name']}`
            const variantPrice = `${formatPriceNumber(Number(item.attributes['order.product.price'])
            * Number(item.attributes['order.product.quantity']))} ${currency}`

            const backInStockDate = item?.product?.product?.find((prod) => prod['product.code'] === item.attributes?.['order.product.prodcode'])?.['stock.dateback'] ?? null
            return ({
              quantity: variantQuantity,
              variantLabel,
              variantPrice,
              backInStockDate,
              id: Number(item.id),
            })
          }) ?? [],

        })
      }) ?? [],
  }
  ))
}

export const isAnyProductOverManualMoq = (
  suppliers: SupplierMappedProducts[],
  included?: OrderInclude[],
): boolean => suppliers.some((supplier) => supplier?.products?.some(
  ({ product, attributes }) => getIsProductOverManualStockLimit(product, included, attributes),
))

const getCartAddressObject = (
  cartResponse: CartResponse,
): OrderAddressInclude | undefined => getIncluded(cartResponse)
  ?.filter(isAddressInclude)
  ?.find((include) => include.type === 'basket.address')

export const getAddressObject = (
  resource: CartResponse | SingleOrderResponse,
) => {
  if (isCartResource(resource)) {
    return getCartAddressObject(resource)
  }
  return getOrderAddressObject(resource)
}

export type OrderConfirmationDataObjectType = ReturnType<typeof getOrderConfirmationDataObject>

export const getCartCurrency = (
  cart: WithSuppliers<CartResponse> | SingleOrderResponse | null,
) => Currency[cart?.data?.attributes?.['order.currencyid'] || DEFAULT_CURRENCY]

export const getOrderConfirmationDataObject = (
  orderData: WithSuppliers<SingleOrderResponse>,
  orderId: string | null,
  countryAndLocale: string | undefined,
  t: Translate,
  customerResponse?: CustomerResponse | null,
) => {
  const { locale, country } = getCountryAndLocaleStrings(countryAndLocale)
  const taxRate = +getTaxRate(country).toFixed(2)
  const { supplierMappedProducts: suppliers, included } = getSupplierProducts(orderData)
  const shippingTotal = Number(orderData.data?.attributes?.['order.costs'] ?? 0)
  const vatTotal = +Number(orderData.data?.attributes['order.taxvalue'] ?? 0).toFixed(2)
  const subTotal = +Number(orderData.data?.attributes?.['order.price'] ?? 0) + shippingTotal
  const grandTotal = +(subTotal + vatTotal).toFixed(2)
  const currency = getCartCurrency(orderData)
  const totalOrderDiscount = getTotalDiscount(suppliers, orderData).toFixed(2)
  const address = getAddressObject(orderData)
  // Use customer email as fallback if address email is not available
  const { email: customerEmail } = selectNormalizedCustomer(customerResponse || null)
  const email = address?.attributes?.['order.address.email'] || customerEmail || ''
  const addressText = address?.attributes?.['order.address.address1']
  const addressDetails = address?.attributes?.['order.address.city'] /** @deprecated data field, only show for old orders */
  const phone = address?.attributes['order.address.telephone']
  const notes = orderData.data?.attributes?.['order.comment']
  const orderedProducts = getOrderConfirmationProducts(suppliers, included, currency, t)
  const orderDate = new Date().toLocaleString('fi-FI')
  const { invoiceType, businessId, reference, sendOrderConfirmation } = getPaymentData(orderData)
  const allProducts = orderedProducts.flatMap((product) => product.products)

  const parsedSuppliers = suppliers.map((supplier) => {
    const { supplierCode, totalProductCount } = supplier

    const { deliveryTime } = getSupplierDeliveryDetails(supplier.supplierInfo, country)

    return {
      supplierCode,
      totalProductCount,
      shipmentDays: deliveryTime,
      supplierName: supplier.supplierInfo?.['supplier.label'] || '',
    }
  })

  const parsedProducts = allProducts.map((product) => {
    if (!product) return null
    const { productCode, productName, addedProducts, priceWithSalesUnit } = product

    const supplier = parsedSuppliers.find((sup) => product.supplier?.[0]['supplier.code'] === sup.supplierCode)

    const productUrl = `${getBasePathWithLocale(countryAndLocale as CountryAndLocale)}${getPathName(product as Product, t)}`

    return {
      productSKU: productCode,
      productName,
      productVariants: addedProducts,
      productUrl,
      priceWithSalesUnit,
      supplier,
    }
  })

  return {
    parsedSuppliers,
    orderDate,
    invoiceType,
    reference,
    email,
    phone,
    businessId,
    addressText,
    addressDetails, /** @deprecated data field, only show for old orders */
    notes,
    parsedProducts,
    shippingTotal,
    vatTotal,
    subTotal,
    grandTotal,
    currency,
    taxRate,
    totalOrderDiscount: (+totalOrderDiscount).toFixed(2),
    language: locale,
    country,
    orderId: orderId || '',
    sendOrderConfirmation,
  }
}

export const getCartProductIds = (
  mainCart: CartOrderResponse | SingleOrderResponse | null,
): string[] | undefined => {
  if (!mainCart) {
    return undefined
  }
  return getIncluded(mainCart)
    .filter(isProductInclude)
    .map((product) => product.attributes['order.product.productid'])
}

export const getEmail = (deliveryAddress: CartAddress | undefined) => deliveryAddress?.['order.address.email'] || ''

export const getDeliveryNotes = (attributes: OrderAttributes | undefined) => attributes?.['order.comment']

export const getOrderDate = (attributes: OrderAttributes | undefined) => attributes?.['order.ctime']

export const getCartAddressCountry = (address: CartAddress | undefined) => CountryData.find(
  (country) => country.locale === address?.['order.address.countryid']?.toLowerCase(),
)?.name

export const getTotalLowerMovBrands = (
  modifiedCart: CartSupplierProducts | null,
  country: ActiveCountryId,
): number => modifiedCart?.supplierMappedProducts.reduce((total, supplier) => {
  const supplierTotal = modifiedCart
    ? selectSupplierTotal(modifiedCart, supplier)
    : 0
  const { minimumOrderValue: mov } = getSupplierDeliveryDetails(supplier.supplierInfo, country)
  return mov > (supplierTotal || 0) ? total + 1 : total
}, 0) || 0

export const getUnderMovProductList = (
  cart: WithSuppliers<CartResponse>,
  country: ActiveCountryId,
): OrderProductInclude<OrderType>[] => {
  const filteredIncludedProducts = getProductIncluded(cart) || []

  const underMovProductList: OrderProductInclude[] = []
  const modifiedCart = cart ? getSupplierGroupByProducts(cart) : null

  modifiedCart?.supplierMappedProducts.forEach((currentSupplier) => {
    const {
      minimumOrderValue: mov,
    } = getSupplierDeliveryDetails(currentSupplier.supplierInfo, country)
    const supplierTotal = selectSupplierTotal(cart, currentSupplier)

    if (getIsUnderMov(supplierTotal, mov)) {
      // Filter all cart products that belongs the lower MOV supplier
      // so that variant products could also be captured by doing so
      const underMovProducts = filteredIncludedProducts.filter(
        ({ product }) => product?.supplier[0]['supplier.code'] === currentSupplier.supplierCode,
      )
      underMovProductList.push(...underMovProducts)
    }
  })

  return underMovProductList
}

export const getCartMaxPrice = (cart: CartSupplierProducts): number => (
  cart.included
    .filter(isProductInclude)
    .reduce((total, { product, attributes }) => (
      total + (product ? getPrices(product, 1).default.price : 1) * attributes['order.product.quantity']
    ), 0)
)

export const getCartProductTotal = (cart: CartResponse | null): number => (
  cart?.included
    .filter(isProductInclude)
    .reduce((total, productInclude) => (
      total + Number(productInclude.attributes['order.product.price']) * Number(productInclude.attributes['order.product.quantity'])
    ), 0) || 0
)

export const getCartProductRebateTotal = (cart: CartResponse | null): number => (
  cart?.included
    .filter(isProductInclude)
    .reduce((total, productInclude) => (
      total + Number(productInclude.attributes['order.product.rebate']) * Number(productInclude.attributes['order.product.quantity'])
    ), 0) || 0
)

export const getCartTotal = (cart: CartSupplierProducts, country: ActiveCountryId) => {
  if (!isSupplierMappedResource(cart)) {
    console.error('Wrong instance of cart passed to selectCartTotal')
    return 0
  }

  const { supplierMappedProducts } = cart
  return supplierMappedProducts
    .filter((supplier) => {
      const { isUnderMov } = getSupplierMovData(supplier.supplierCode, country, cart)
      return !isUnderMov
    })
    .reduce((accumulator, supplier) => {
      const supplierTotal = selectSupplierTotal(cart, supplier)
      return accumulator + (supplierTotal || 0)
    }, 0)
}

export const getTotalCartShippingCost = (
  modifiedCart: CartSupplierProducts | null,
  country: ActiveCountryId,
): { totalShippingDiscount: number, shippingTotal: number } => (
  modifiedCart?.supplierMappedProducts
    ?.filter((supplier) => {
      const { isUnderMov } = getSupplierMovData(
        supplier.supplierCode, country, modifiedCart,
      )
      return !isUnderMov
    })
    .reduce(({ shippingTotal, totalShippingDiscount }, supplier) => {
      const { supplierInfo, products } = supplier
      const cartSupplierShippingPrice = selectSupplierShipping(modifiedCart, supplier)
      const isImportedProduct = getIsImportedSupplier(products)

      const {
        deliveryCost,
      } = getSupplierDeliveryDetails(supplierInfo, country)

      if (cartSupplierShippingPrice !== deliveryCost && !isImportedProduct) {
        return {
          shippingTotal,
          totalShippingDiscount: totalShippingDiscount + deliveryCost,
        }
      }

      return {
        shippingTotal: shippingTotal + cartSupplierShippingPrice,
        totalShippingDiscount,
      }
    }, { totalShippingDiscount: 0, shippingTotal: 0 })
  ?? { totalShippingDiscount: 0, shippingTotal: 0 }
)

export const getOrderConfirmationAddressData = (orderData: WithSuppliers<SingleOrderResponse>) => {
  if (isOrderResource(orderData)) {
    const {
      addressCity: deliveryAddressCity,
      addressDetails: deliveryAddressDetails, // @deprecated
      addressText: deliveryAddressText,
      addressPostcode: deliveryAddressPostcode,
      addressEmail: deliveryAddressEmail,
      addressPhone: deliveryAddressPhone,
      addressCountry: deliveryAddressCountry,
    } = selectOrderAddressData(orderData, 'delivery')

    const {
      addressText: billingAddressText,
      addressCompany: billingAddressCompany,
      addressCountry: billingAddressCountry,
    } = selectOrderAddressData(orderData, 'payment')

    return {
      deliveryAddressCity,
      deliveryAddressDetails,
      deliveryAddressText,
      deliveryAddressPostcode,
      deliveryAddressEmail,
      deliveryAddressPhone,
      deliveryAddressCountry,
      billingAddressText,
      billingAddressCompany,
      billingAddressCountry,
    }
  }

  // Guest user – order data is only available for logged in users, use cart data instead
  const { deliveryAddress, billingAddress } = selectCartAddress(orderData)

  // TODO: Refactor cart address selectors to differentiate between billing and delivery addresses
  // and refactor below to use those selectors
  return {
    deliveryAddressCity: deliveryAddress?.['order.address.city'] || '',
    deliveryAddressText: deliveryAddress?.['order.address.address1'] || '',
    deliveryAddressPostcode: deliveryAddress?.['order.address.postal'] || '',
    deliveryAddressEmail: deliveryAddress?.['order.address.email'] || '',
    deliveryAddressPhone: deliveryAddress?.['order.address.telephone'] || '',
    deliveryAddressCountry: CountryData.find(
      (countryItem) => countryItem.locale === deliveryAddress?.['order.address.countryid']?.toLowerCase(),
    )?.name || '',
    billingAddressText: billingAddress?.['order.address.address1'] || '',
    billingAddressCompany: billingAddress?.['order.address.company'] || '',
    billingAddressCountry: CountryData.find(
      (countryItem) => countryItem.locale === billingAddress?.['order.address.countryid']?.toLowerCase(),
    )?.name || '',
  }
}

export const generateShareCartUrl = (
  cart: WithSuppliers<CartResponse> | CartSupplierProducts,
  countryAndLocale: CountryAndLocale,
  urlT: (path: Url) => string,

) => {
  const basePath = getBasePathWithLocale(countryAndLocale)

  /* Generate a string that's comprised of the product SKU code, in case of variants - also
   the variant id, and the quantity for that product, separated by a '+',
   e.g. ABC-12:510:5+ABC-12:512:20+LHT123:5 */
  const skuAndQuantitiesString = cart?.included?.map((productData) => {
    // Exit early if the included data is not a cart product data
    if (!isProductInclude(productData) || !productData.product) return ''
    const { attributes, product } = productData

    const quantity = attributes['order.product.quantity']
    const productCode = product?.['product.code']
    let variantId = ''

    // Check if the selected product is a variant product, then assign a variantId
    if (attributes?.attribute?.length) {
      const variantName = attributes.attribute[0]['order.product.attribute.name']

      const relevantProduct = product?.product
        ?.find((prodObj) => prodObj.attribute[0]['attribute.label'].toLowerCase() === variantName.toLowerCase())

      variantId = relevantProduct?.attribute[0]['attribute.id'] || ''
    }

    return `${productCode}${variantId ? `:${variantId}` : ''}:${quantity}`
  }).filter(Boolean).join('+')

  return urlT(`${basePath}/cart/share/${skuAndQuantitiesString}`)
}
