import { v4 as uuidv4 } from 'uuid'

import { CLIENT_ENCRYPTION_SALT, DEFAULT_COOKIE_DOMAIN, DEFAULT_COOKIE_EXPIRATION_DAYS, DROPPE_EMAIL_REGEX, INTERNAL_TRAFFIC_COOKIE_NAME } from './constants'
import { isProduction } from './environment'
import { encryptStringBrowser } from './util'

interface IsInternalTrafficCookie {
  email: string
  encryptedEmail: string
}

// Cookie CRUD operations

export const createCookie = (
  cookieName: string,
  cookieValue: string,
  daysUntilExpiration: number | null = DEFAULT_COOKIE_EXPIRATION_DAYS,
  cookieDomain = DEFAULT_COOKIE_DOMAIN,
) => {
  let cookieString = `${encodeURIComponent(cookieName)}=${encodeURIComponent(cookieValue)}; path=/; domain=${cookieDomain}`

  // If daysUntilExpiration is null create session based cookie
  if (daysUntilExpiration !== null) {
    const expirationDate = new Date()
    expirationDate.setTime(expirationDate.getTime() + (daysUntilExpiration * 24 * 60 * 60 * 1000))
    cookieString += `; expires=${expirationDate.toUTCString()}`
  }

  document.cookie = cookieString
}

export const getCookie = (name: string) => {
  if (typeof document === 'undefined') {
    return null
  }

  const cookies = document.cookie.split(';')
  const foundCookie = cookies.find((cookie) => {
    const [cookieName] = cookie.split('=')
    return cookieName.trim() === name
  })

  if (foundCookie) {
    const [cookieName, cookieValue] = foundCookie.split('=')
    return [decodeURIComponent(cookieName), decodeURIComponent(cookieValue)]
  }

  return null
}

export const hasCookie = (name: string) => !!getCookie(name)?.length

export const deleteCookie = (cookieName: string, domain = DEFAULT_COOKIE_DOMAIN) => {
  document.cookie = `${cookieName}=; path=/; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:00 GMT`
}

/**
 * Triggers a prompt for an internal user to log in using a @droppe.fi email
 * The email and it's encrypted version is then saved as isInternalTraffic
 * cookie value
 */
export const triggerInternalTrafficPrompt = async () => {
  // eslint-disable-next-line no-alert
  const email = window.prompt('Please enter your Droppe email:')?.toLowerCase() || ''

  // Check that email address is valid
  const regex = DROPPE_EMAIL_REGEX
  if (!regex.test(email)) {
    // eslint-disable-next-line no-alert
    window.alert('Incorrect email address, please try again.')
    triggerInternalTrafficPrompt()
    return
  }

  const encryptedEmail = await encryptStringBrowser(email, CLIENT_ENCRYPTION_SALT)
  const cookieValue = {
    email,
    encryptedEmail,
  }

  createCookie(INTERNAL_TRAFFIC_COOKIE_NAME, JSON.stringify(cookieValue))

  // Reload page to initiate datalayer with updated cookie value
  window.location.reload()
}

/**
 * If internal cookie exists, return the values as a javascript object,
 * else return the same object with empty values to enable variable destructring.
 * @returns Object containing internal user email and encrypted email
 */
export const getParsedInternalTrafficCookie = (): IsInternalTrafficCookie => {
  const internalTrafficCookie = getCookie(INTERNAL_TRAFFIC_COOKIE_NAME)

  if (internalTrafficCookie?.length) {
    const [,cookieValue] = internalTrafficCookie

    try {
      // Replace old cookie with new one, do not trigger in production
      if ((cookieValue === 'true' || cookieValue === 'false') && !isProduction) {
        window.onload = () => {
          triggerInternalTrafficPrompt()
        }
      }

      return JSON.parse(cookieValue)
    } catch (e) {
      // If cookie isn't JSON parsable, create a new one.
      if (!isProduction) {
        window.onload = () => {
          triggerInternalTrafficPrompt()
        }
      }
      console.error(e)
    }
  }

  return {
    email: '',
    encryptedEmail: '',
  }
}

export const generateDroppeClientIDCookie = () => {
  const clientId = uuidv4()
  createCookie('droppe_client_id', `cid-${clientId}`, DEFAULT_COOKIE_EXPIRATION_DAYS * 2)
}

export const clearCookie = (name: string, domain: string): void => {
  if (typeof document !== 'undefined') {
    document.cookie = `${name}=CLEAR;domain=${domain};Path=/;expires=Sat, 01-Jan-2000 00:00:00 GMT`
  }
}
