import { useEffect } from 'react'

import models, { AppModels } from '../cypress/utils/models'

type ModelName = keyof AppModels

type ModelProps<T extends ModelName> = {
  [K in keyof ConstructorParameters<typeof models[T]>[0]]:
  ConstructorParameters<typeof models[T]>[0][K]
}

const getExistingModel = <T extends ModelName>(modelName: T) => (
  (typeof window !== 'undefined' ? window : {}) as unknown as AppModels
)[modelName]

const initializeModel = <T extends ModelName>(modelName: T, modelProps: Partial<ModelProps<T>>) => {
  const existingModel = getExistingModel(modelName)
  const mergedProps = { ...existingModel, ...modelProps }
  const model = new models[modelName](mergedProps as any)

  if (window.Cypress) {
    (window as unknown as AppModels)[modelName] = model as any
  }
}

const useCyModel = <T extends ModelName>(modelName: T, modelProps: ModelProps<T>) => {
  useEffect(() => {
    initializeModel(modelName, modelProps)
  }, [modelProps, modelName])
}

export default useCyModel

export const useCyModelWithProps = <T extends ModelName>(
  modelName: T,
  modelProps: Partial<ModelProps<T>>,
) => {
  useEffect(() => {
    const existingModel = getExistingModel(modelName)

    if (!existingModel) {
      return
    }

    initializeModel(modelName, modelProps)
  }, [modelProps, modelName])
}
