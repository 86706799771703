import { createSelector } from 'reselect'

import { CustomerResponse, AddressInclude, PropertyInclude } from '../../../utils/types/customer'
import { CountryData, CountryId } from '../../../utils/constants'
import { getCountryRelatedText } from '../../../utils/customer'
import { COUNTRY_DROPDOWN_DATA } from '../../../utils/forms'
import { OrderAddressInclude, SingleOrderResponse } from '../../../utils/types/Order'
import { isOrderAddress } from '../../../lib/OrderHistory'

export const selectCustomerById = (
  customerResponse: CustomerResponse | null,
  customerId: string,
) => (
  customerResponse?.data.find(
    (customer) => customer.attributes['customer.id'] === customerId,
  )
)

export const selectCustomerByIndex = (
  customerResponse: CustomerResponse | null,
  index = 0,
) => customerResponse?.data[index]

const selectCustomerData = createSelector(
  (customerResponse: CustomerResponse | null) => customerResponse,
  (_: CustomerResponse | null, idx?: string | number) => idx,
  (customerResponse, idx) => {
    if (typeof idx === 'string' && !!idx) {
      return selectCustomerById(customerResponse, idx)
    }

    return selectCustomerByIndex(customerResponse, idx as number | undefined)
  },
)

export const isBillingAddress = (address: AddressInclude | PropertyInclude): address is AddressInclude => (address as AddressInclude).attributes['customer.address.type'] === 'payment'
export const isDeliveryAddress = (address: AddressInclude | PropertyInclude): address is AddressInclude => (address as AddressInclude).attributes['customer.address.type'] === 'delivery'

export const selectCustomerCount = (
  customerResponse: CustomerResponse | null,
) => customerResponse?.data?.length ?? 0

const selectCustomerLinks = (
  customerResponse: CustomerResponse | null | undefined,
) => customerResponse?.links

export const selectCustomerIncluded = (
  customerResponse: CustomerResponse | null,
): (AddressInclude | PropertyInclude)[] | undefined => customerResponse?.included

export const selectCustomer = createSelector(
  selectCustomerData,
  (customerData) => customerData?.attributes,
)

export const selectCustomerId = createSelector(
  selectCustomer,
  (customer) => customer?.['customer.id'],
)
export const selectCustomerEmail = createSelector(
  selectCustomer,
  (customer) => customer?.['customer.email'],
)

export const selectCustomerFirstName = createSelector(
  selectCustomer,
  (customer) => customer?.['customer.firstname'],
)

export const selectCustomerLastName = createSelector(
  selectCustomer,
  (customer) => customer?.['customer.lastname'],
)

export const selectCustomerFullName = createSelector(
  selectCustomerFirstName,
  selectCustomerLastName,
  (firstName, lastName) => {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`
    }

    if (firstName) {
      return firstName
    }

    if (lastName) {
      return lastName
    }

    return undefined
  },
)

export const selectCustomerPhone = createSelector(
  selectCustomer,
  (customer) => customer?.['customer.telephone'],
)

export const selectCustomerCompany = createSelector(
  selectCustomer,
  (customer) => customer?.['customer.company'],
)

export const selectCustomerRole = createSelector(
  selectCustomer,
  (customer) => customer?.['customer.title'],
)

export const selectCustomerBusinessId = createSelector(
  selectCustomer,
  (customer) => customer?.['customer.vatid'] || customer?.['customer.businessid'],
)

export const selectCustomerParentId = createSelector(
  selectCustomer,
  (customer) => customer?.['customer.parentid'],
)

export const selectCustomerPostal = createSelector(
  selectCustomer,
  (customer) => customer?.['customer.postal'],
)

export const selectCustomerAddress = createSelector(
  selectCustomer,
  (customer) => customer?.['customer.address1'],
)

export const selectCustomerNotes = createSelector(
  selectCustomer,
  (customer) => customer?.['customer.paymentnotes'],
)

export const selectCustomerCity = createSelector(
  selectCustomer,
  (customer) => customer?.['customer.city'],
)

export const selectCustomerCountryId = createSelector(
  selectCustomer,
  (customer) => customer?.['customer.countryid']?.toLocaleLowerCase() as CountryId,
)

export const selectIsCustomerAdmin = createSelector(
  selectCustomer,
  (customer) => customer?.['customer.admin'] === '1',
)

export const selectCustomerCountry = createSelector(
  selectCustomerCountryId,
  (countryId) => COUNTRY_DROPDOWN_DATA
    .find((country) => country.value.toLowerCase() === countryId)?.label,
)

export const selectCustomerCountryRelatedText = createSelector(
  selectCustomerPostal,
  selectCustomerCountry,
  selectCustomerCity,
  (postal, country, city) => getCountryRelatedText(postal, country, city),
)

export const selectCustomerAddresses = createSelector(
  selectCustomerIncluded,
  (customerIncluded) => customerIncluded?.filter((item): item is AddressInclude => (item as AddressInclude).type === 'customer.address'),
)

export const selectCustomerDeliveryAddresses = createSelector(
  selectCustomerAddresses,
  (customerAddresses) => customerAddresses?.filter(isDeliveryAddress),
)

export const selectCustomerBillingAddresses = createSelector(
  selectCustomerAddresses,
  (customerAddresses) => customerAddresses?.filter(isBillingAddress),
)

export const selectCustomerPropertyList = createSelector(
  selectCustomerIncluded,
  (customerIncluded) => [
    ...new Set(
      customerIncluded
        ?.filter((item): item is PropertyInclude => item.type === 'customer.property')
        .map((item) => item.attributes['customer.property.value']),
    ),
  ],
)

export const selectCustomerAddressInclude = (
  customerAddress?: AddressInclude,
) => customerAddress

export const selectCustomerAddressId = createSelector(
  selectCustomerAddressInclude,
  (addressInclude) => addressInclude?.attributes['customer.address.id'],
)

export const selectCustomerAddress1 = createSelector(
  selectCustomerAddressInclude,
  (addressInclude) => addressInclude?.attributes['customer.address.address1'],
)

export const selectCustomerAddressCity = createSelector(
  selectCustomerAddressInclude,
  (addressInclude) => addressInclude?.attributes['customer.address.city'],
)

export const selectCustomerAddressPostal = createSelector(
  selectCustomerAddressInclude,
  (addressInclude) => addressInclude?.attributes['customer.address.postal'],
)

export const selectCustomerAddressCountryId = createSelector(
  selectCustomerAddressInclude,
  (addressInclude) => addressInclude?.attributes['customer.address.countryid']?.toLocaleLowerCase() as CountryId,
)

export const selectCustomerAddressCountry = createSelector(
  selectCustomerAddressCountryId,
  (countryId) => COUNTRY_DROPDOWN_DATA
    .find((country) => country.value.toLowerCase() === countryId)?.label,
)

export const selectCustomerAddressPostalAndCountry = createSelector(
  selectCustomerAddressPostal,
  selectCustomerAddressCountry,
  selectCustomerAddressCity,
  (postal, country, city) => getCountryRelatedText(postal, country, city),
)

export const selectCustomerAddressCompany = createSelector(
  selectCustomerAddressInclude,
  (addressInclude) => addressInclude?.attributes['customer.address.company'],
)

/**
 * This selector is used as "Address Details" for delivery address which has
 * been deprecated and only used for old orders. In case of billing address,
 * it is used as "Invoice Type"
 */
export const selectCustomerAddressDetails = createSelector(
  selectCustomerAddressInclude,
  (addressInclude) => addressInclude?.attributes['customer.address.addressdetails'],
)

export const selectCustomerAddressNotes = createSelector(
  selectCustomerAddressInclude,
  (addressInclude) => addressInclude?.attributes['customer.address.deliverynotes'],
)

export const selectCustomerAddressPosition = createSelector(
  selectCustomerAddressInclude,
  (addressInclude) => addressInclude?.attributes['customer.address.position'],
)

export const selectCustomerAddressBusinessId = createSelector(
  selectCustomerAddressInclude,
  (addressInclude) => addressInclude?.attributes['customer.address.vatid'],
)

export const selectCustomerAddressEmail = createSelector(
  selectCustomerAddressInclude,
  (addressInclude) => addressInclude?.attributes['customer.address.email'],
)

export const selectCustomerAddressPhoneNumber = createSelector(
  selectCustomerAddressInclude,
  (addressInclude) => addressInclude?.attributes['customer.address.telephone'],
)

export const selectIsMainAddress = createSelector(
  selectCustomerAddressInclude,
  (addressInclude) => addressInclude?.attributes['customer.address.position'] === 0,
)

export const selectCustomerLink = createSelector(
  selectCustomerData,
  (customerData) => customerData?.links.self.href,
)

export const selectCustomerStatus = createSelector(
  selectCustomer,
  (customerData) => customerData?.['customer.status'],
)

export const selectNormalizedCustomer = createSelector(
  selectCustomerId,
  selectCustomerFirstName,
  selectCustomerLastName,
  selectCustomerFullName,
  selectCustomerEmail,
  selectCustomerPhone,
  selectCustomerCompany,
  selectCustomerRole,
  selectCustomerBusinessId,
  selectCustomerCity,
  selectCustomerCountry,
  selectCustomerCountryId,
  selectCustomerPostal,
  selectCustomerCountryRelatedText,
  selectCustomerAddress,
  selectCustomerAddresses,
  selectCustomerDeliveryAddresses,
  selectCustomerBillingAddresses,
  selectCustomerNotes,
  selectIsCustomerAdmin,
  selectCustomerParentId,
  selectCustomerLink,
  selectCustomerPropertyList,
  selectCustomerStatus,
  (
    id,
    firstName,
    lastName,
    fullName,
    email,
    phone,
    company,
    role,
    businessId,
    city,
    country,
    countryId,
    postal,
    countryRelatedText,
    address,
    addresses,
    deliveryAddresses,
    billingAddresses,
    paymentNotes,
    isAdmin,
    parentId,
    link,
    properties,
    status,
  ) => ({
    id,
    firstName,
    lastName,
    fullName,
    email,
    phone,
    company,
    role,
    businessId,
    city,
    country,
    countryId,
    postal,
    countryRelatedText,
    address,
    addresses,
    deliveryAddresses,
    billingAddresses,
    paymentNotes,
    isAdmin,
    parentId,
    link,
    properties,
    status,
  }),
)

export const selectNormalizedDeliveryAddress = createSelector(
  selectCustomerAddressId,
  selectCustomerAddress1,
  selectCustomerAddressPostal,
  selectCustomerAddressCity,
  selectCustomerAddressCountryId,
  selectCustomerAddressCountry,
  selectCustomerAddressPostalAndCountry,
  selectCustomerAddressDetails,
  selectCustomerAddressNotes,
  selectCustomerAddressPosition,
  selectIsMainAddress,
  selectCustomerAddressPhoneNumber,
  (
    addressId,
    address1,
    postal,
    city,
    countryId,
    country,
    countryRelatedText,
    /** @deprecated data field, only show for old orders */
    addressDetails,
    notes,
    position,
    isMainAddress,
    phoneNumber,
  ) => ({
    addressId,
    address1,
    postal,
    city,
    countryId,
    country,
    countryRelatedText,
    addressDetails, /** @deprecated data field, only show for old orders */
    notes,
    position,
    isMainAddress,
    phoneNumber,
  }),
)

export const selectNormalizedBillingAddress = createSelector(
  selectCustomerAddressId,
  selectCustomerAddress1,
  selectCustomerAddressPostal,
  selectCustomerAddressCity,
  selectCustomerAddressCountryId,
  selectCustomerAddressCountry,
  selectCustomerAddressPostalAndCountry,
  selectCustomerAddressDetails,
  selectCustomerAddressNotes,
  selectCustomerAddressCompany,
  selectCustomerAddressPosition,
  selectCustomerAddressBusinessId,
  selectCustomerAddressEmail,
  selectIsMainAddress,
  (
    addressId,
    address1,
    postal,
    city,
    countryId,
    country,
    countryRelatedText,
    invoiceType,
    notes,
    company,
    position,
    businessId,
    email,
    isMainAddress,
  ) => ({
    addressId,
    address1,
    postal,
    city,
    countryId,
    country,
    countryRelatedText,
    invoiceType,
    notes,
    company,
    position,
    businessId,
    email,
    isMainAddress,
  }),
)

export const selectCustomerAddressHref = createSelector(
  selectCustomerLinks,
  (links) => links?.['customer/address'].href,
)

// Below selectors are used for order address data (not user/customer address data)
export const selectOrderAddressObject = createSelector(
  (orderResponse: SingleOrderResponse) => orderResponse,
  (_: SingleOrderResponse, addressType: 'delivery' | 'payment') => addressType,
  (orderResponse, addressType) => orderResponse.included
    ?.filter(isOrderAddress)
    ?.find((addressObject: OrderAddressInclude) => addressObject.attributes['order.address.type'] === addressType),
)

export const selectOrderAddressData = createSelector(
  selectOrderAddressObject,
  (addressObject) => ({
    addressId: addressObject?.attributes?.id,
    addressType: addressObject?.attributes?.['order.address.type'] || '',
    addressText: addressObject?.attributes?.['order.address.address1'] || '',
    addressCity: addressObject?.attributes?.['order.address.city'] || '',
    addressPostcode: addressObject?.attributes?.['order.address.postal'] || '',
    addressCountryId: addressObject?.attributes?.['order.address.countryid'] || '',
    addressCountry: CountryData.find(
      (country) => country.locale === addressObject?.attributes?.['order.address.countryid']?.toLowerCase(),
    )?.name,
    addressCompany: addressObject?.attributes?.['order.address.company'] || '',
    addressFirstname: addressObject?.attributes?.['order.address.firstname'] || '',
    addressLastname: addressObject?.attributes?.['order.address.lastname'] || '',
    addressPhone: addressObject?.attributes?.['order.address.telephone'] || '',
    addressEmail: addressObject?.attributes?.['order.address.email'] || '',
    /** addressDetails is a @deprecated data field, only show for old orders */
    addressDetails: addressObject?.attributes?.addressDetails || '',
  }),
)
