import { FormValues, StepName } from '../../../components/Modals/ModalFlows/SampleRequest/utils'
import { libraryFunctions } from '../../../lib/resources/price'
import { Currency, QUARTERLY_ORDER_VOLUME_THRESHOLDS } from '../../../utils/constants'
import { Product, ProductQuantity } from '../../../utils/types/Product'

const { getProductPrice } = libraryFunctions

type RequestForSampleModel = {
  product: Product
  formValues: FormValues
  isLeadQualified: boolean
  productQuantity?: ProductQuantity[]
  setProductQuantity?: React.Dispatch<React.SetStateAction<ProductQuantity[]>>
  updateValues: (key: StepName) => (values: FormValues[StepName]) => void
  onSubmit: () => void
  onProductDetailsSubmit: () => void
  goToNextStep: () => void
  closeModal: () => void
  setIsQualified?: (isQualified: boolean) => void
  addToCart?: () => Promise<void>
}

class RequestForSample {
  product: RequestForSampleModel['product']

  formValues: RequestForSampleModel['formValues']

  updateValues: RequestForSampleModel['updateValues']

  isLeadQualified: RequestForSampleModel['isLeadQualified']

  onSubmit: RequestForSampleModel['onSubmit']

  onProductDetailsSubmit: RequestForSampleModel['onProductDetailsSubmit']

  goToNextStep: RequestForSampleModel['goToNextStep']

  closeModal: RequestForSampleModel['closeModal']

  setIsQualified?: RequestForSampleModel['setIsQualified']

  addToCart?: RequestForSampleModel['addToCart']

  productQuantity?: RequestForSampleModel['productQuantity']

  setProductQuantity?: RequestForSampleModel['setProductQuantity']

  constructor(model: RequestForSampleModel) {
    const {
      product,
      formValues,
      isLeadQualified,
      updateValues,
      onSubmit,
      onProductDetailsSubmit,
      goToNextStep,
      closeModal,
      addToCart,
      productQuantity,
      setProductQuantity,
      setIsQualified,
    } = model

    this.product = product
    this.formValues = formValues
    this.isLeadQualified = isLeadQualified
    this.updateValues = updateValues
    this.onSubmit = onSubmit
    this.onProductDetailsSubmit = onProductDetailsSubmit
    this.goToNextStep = goToNextStep
    this.closeModal = closeModal
    this.addToCart = addToCart
    this.productQuantity = productQuantity
    this.setProductQuantity = setProductQuantity
    this.setIsQualified = setIsQualified

    this.getQualifiedOrderSize = this.getQualifiedOrderSize.bind(this)
  }

  getQualifiedOrderSize() {
    const productPrice = getProductPrice(this.product)
    return Math.ceil((QUARTERLY_ORDER_VOLUME_THRESHOLDS[Currency.EUR] * 4) / productPrice)
  }
}

export default RequestForSample
