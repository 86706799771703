import TagManager from 'react-gtm-module'

import { Product } from '../types/Product'
import { emptyEcomDataLayer, getAnalyticsProduct } from './utils'
import { CountryId } from '../constants'
import { getCurrencyCode } from '../locales'
import { getPrices } from '../../lib/getPrices'

interface ViewItemParams {
  product: Product
  country: CountryId
  getLocalizedProductUrl: (product?: Product) => string
}

const viewItem = ({ product, country, getLocalizedProductUrl }: ViewItemParams) => {
  const analyticsProduct = getAnalyticsProduct({ product, country, getLocalizedProductUrl })
  const currency = getCurrencyCode(country)

  // Note, take rate is already adjusted so getter can be used without extra params
  const value = getPrices(product, 1).final.price

  emptyEcomDataLayer() // First empty datalayer
  TagManager.dataLayer({
    dataLayer: {
      event: 'view_item',
      ecommerce: {
        currency,
        value,
        items: [analyticsProduct],
      },
    },
  })
}

export default viewItem
