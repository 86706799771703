import { FC, ReactNode, SyntheticEvent } from 'react'
import { Button, Divider, Modal, ModalProps } from 'rsuite'

import styles from '../../styles/BaseModal.module.less'

interface BaseModalProps extends ModalProps {
  isOpen: boolean
  closeModal?: (event: SyntheticEvent) => void
  modalAction?: (event: SyntheticEvent) => void
  actionButtonText?: string
  disableActionButton?: boolean
  hideButtons?: boolean
  closeButtonText?: string
  title?: string | ReactNode
  children?: ReactNode
  footer?: ReactNode
  hasFooter?: boolean
  backdrop?: boolean | 'static'
  shouldDisplayCloseIcon?: boolean
  additionalClassName?: string
  modalClassName?: string
  modalHeaderClassName?: string
  actionButtonClasName?: string
  showTitleDivider?: boolean
  testIds?: Partial<{
    modalTestId: string
    closeButtonTestId: string
  }>
}

const BaseModal: FC<BaseModalProps> = (props) => {
  const {
    isOpen = true,
    closeModal,
    modalAction,
    actionButtonText,
    disableActionButton = false,
    hideButtons = false,
    closeButtonText,
    title,
    children,
    footer = null,
    hasFooter = true,
    backdrop = true,
    shouldDisplayCloseIcon = true,
    testIds = {},
    additionalClassName = '',
    modalClassName = '',
    modalHeaderClassName = '',
    actionButtonClasName = '',
    showTitleDivider = false,
    backdropClassName,
    container = undefined,
  } = props

  return (
    <Modal
      enforceFocus={false}
      open={isOpen}
      onClose={closeModal}
      dialogClassName={`${styles['modal-dialog']} ${additionalClassName}`}
      data-testid={testIds.modalTestId}
      backdrop={backdrop}
      className={`${styles.modal} ${modalClassName}`}
      backdropClassName={backdropClassName}
      container={container}
    >
      {title && (
        <Modal.Header
          className={modalHeaderClassName}
          closeButton={shouldDisplayCloseIcon}
        >
          {typeof title === 'string' ? <h5>{title}</h5> : title}
          {showTitleDivider && (
            <Divider
              className={`
                  margin-left-spacer-quadruple-negative
                  margin-right-spacer-quadruple-negative
                  margin-top-spacer-double
                  margin-bottom-spacer-tripple`}
            />
          )}
        </Modal.Header>
      )}
      {children && <Modal.Body>{children}</Modal.Body>}
      {hasFooter && (
        <Modal.Footer>
          {!hideButtons && (
            <div className={styles['modal-action-container']}>
              {closeButtonText && (
                <Button
                  title={closeButtonText}
                  appearance="ghost"
                  onClick={closeModal}
                  className={styles['close-button']}
                  data-testid={testIds.closeButtonTestId}
                >
                  {closeButtonText}
                </Button>
              )}
              {actionButtonText && (
                <Button
                  appearance="primary"
                  onClick={modalAction}
                  disabled={disableActionButton}
                  className={actionButtonClasName}
                >
                  {actionButtonText}
                </Button>
              )}
            </div>
          )}
          {footer && (
          <div className={styles['modal-text-footer']}>
            {footer}
          </div>
          )}
        </Modal.Footer>
      )}
    </Modal>
  )
}

export default BaseModal
