import TagManager from 'react-gtm-module'

interface ErrorBoundaryEvent {
  errorType: string
  errorTimestamp: string
  errorStackTrace: string
  errorMessage: string
  pagePath: string
  country: string
  language: string
}

const errorBoundaryEvent = ({
  errorType,
  errorTimestamp,
  errorStackTrace,
  errorMessage,
  pagePath,
  country,
  language,
}: ErrorBoundaryEvent) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'error_boundary',
      error_type: errorType,
      error_timestamp: errorTimestamp,
      error_stack_trace: errorStackTrace,
      error_message: errorMessage,
      page_path: pagePath,
      country,
      language,
    },
  })
}

export default errorBoundaryEvent
