import { FC, useEffect } from 'react'
import { FallbackProps } from 'react-error-boundary'
import useTranslation from 'next-translate/useTranslation'
import FlexboxGrid from 'rsuite/FlexboxGrid'
import Panel from 'rsuite/Panel'
import Button from 'rsuite/Button'
import { Col } from 'rsuite'
import { useRouter } from 'next/router'

import ErrorBoundaryGraphics from '../Graphics/ErrorBoundaryGraphics'
import useContactModal from '../Modals/hooks/useContactModal'
import useUrls from '../../services/useUrls'
import useIsOnMobile from '../../services/useIsOnMobile'
import { ScreenSize } from '../../utils/constants'
import errorBoundaryEvent from '../../utils/analytics/errorBoundaryEvent'
import { getCountryAndLocaleStrings } from '../../utils/locales'

import styles from '../../styles/ErrorBoundary.module.less'
import buttonStyles from '../../styles/CustomButtons.module.less'

const FlexBoxItem = FlexboxGrid.Item

const ActionArea: FC = () => {
  const { pushT } = useUrls()
  const { t } = useTranslation('common')

  const openContactModal = useContactModal('error')

  const isOnMobile = useIsOnMobile(ScreenSize.md)

  return (
    <>
      <FlexboxGrid
        align="middle"
        justify={isOnMobile ? 'center' : 'start'}
      >
        <FlexBoxItem
          as={Col}
          xs={24}
          sm={10}
        >
          <Button
            appearance="primary"
            className={`${styles['return-button']}`}
            onClick={() => pushT('/products')}
            block
          >
            <b>{t('Return to Home Page')}</b>
          </Button>
        </FlexBoxItem>
        <FlexBoxItem
          as={Col}
          xs={24}
          sm={7}
          md={10}
        >
          <button
            className={`${buttonStyles['custom-button']}
                ${buttonStyles['button-caret']}
                ${buttonStyles['button-tertiary']}
                ${styles['contact-button']}
              `}
            type="button"
            onClick={() => openContactModal()}
          >
            <b>{t('Contact us')}</b>
          </button>
        </FlexBoxItem>
      </FlexboxGrid>
    </>
  )
}

export interface ErrorFallbackProps extends FallbackProps {
  errorStackMessage: string
}

const ErrorFallback = (props: ErrorFallbackProps) => {
  const { errorStackMessage, error } = props
  const { message, stack, name } = error

  const { asPath, locale: countryAndLocale } = useRouter()
  const { country, locale } = getCountryAndLocaleStrings(countryAndLocale)
  const { t } = useTranslation('common')

  useEffect(() => {
    // Analytics event
    if (error) {
      errorBoundaryEvent({
        errorType: name || 'Unknown',
        errorTimestamp: new Date().toISOString(),
        errorStackTrace: stack || errorStackMessage,
        errorMessage: message,
        pagePath: `/${countryAndLocale}${asPath}`,
        country,
        language: locale,
      })
    }
  }, [error])

  return (
    <FlexboxGrid
      className={`${styles['fallback-container']} max-width-lg`}
      justify="space-around"
    >
      <FlexBoxItem className={styles['content-container']}>
        <h1 className={styles.title}>
          {t('Something went wrong')}
        </h1>
        <p className={styles.subtitle}>
          {t('errorBoundDescription')}
        </p>
        <ActionArea />
        <div className={styles['panel-wrapper']}>
          <Panel
            collapsible
            bordered
            header={t('Learn more about the reason')}
            className={styles['error-panel']}
          >
            <p data-testid="error-name">
              {message}
            </p>
            <p>
              {errorStackMessage}
            </p>
          </Panel>
        </div>
      </FlexBoxItem>
      <FlexBoxItem>
        <ErrorBoundaryGraphics />
      </FlexBoxItem>
    </FlexboxGrid>
  )
}

export default ErrorFallback
