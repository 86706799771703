import { createSelector } from 'reselect'

import { CartSupplierProducts, OrderPaymentData, SupplierMappedProducts } from '../../utils/types/Cart'
import { CartAddress, CartResponse, OrderInclude, SingleOrderResponse, WithSuppliers } from '../../utils/types/Order'
import { getIncluded, getProductIncluded, isAddressInclude, isOrderServiceAttributeInclude, isProductInclude, isServiceInclude } from '../../utils/types/guards/Order'
import { libraryFunctions as discountLibraryFunctions } from '../../lib/resources/discount'
import { libraryFunctions } from '../../lib/resources/price'

const { getCurrentUpcomingDiscount } = discountLibraryFunctions
const { getDefaultPrice } = libraryFunctions

export const selectCartIncluded = (cart: SingleOrderResponse): OrderInclude[] => cart.included
const selectAttribute = (_: CartResponse, attribute: keyof CartAddress) => attribute
const selectCart = (cart: CartSupplierProducts | SingleOrderResponse) => cart
const selectCartWithSuppliers = (cart: WithSuppliers<CartResponse>) => cart
const selectSupplier = (
  _: CartSupplierProducts | SingleOrderResponse,
  supplier: SupplierMappedProducts,
) => supplier

export const selectCartAddressArray = createSelector(
  selectCartIncluded,
  (included) => included?.filter(isAddressInclude) || [],
)

export const selectOrderData = createSelector(
  selectCart,
  (cart) => cart.data,
)

export const selectOrderParentId = createSelector(
  selectCartWithSuppliers,
  (cart) => cart.data.attributes['order.parentid'],
)

export const selectOrderServiceArray = createSelector(
  selectCartIncluded,
  (included) => included?.filter(isServiceInclude) || [],
)

export const selectOrderServiceAttributeArray = createSelector(
  selectCartIncluded,
  (included) => included?.filter(isOrderServiceAttributeInclude) || [],
)

export const selectCartProducts = createSelector(
  selectCartIncluded,
  (included) => included?.filter(isProductInclude) ?? [],
)

export const selectCartProductsUnique = createSelector(
  selectCartProducts,
  (products) => products
    .filter((product) => !!product.product)
    .filter((currentItem, index, self) => index === self.findIndex((pointerItem) => (
      pointerItem.product?.['product.id'] === currentItem.product?.['product.id']
    ))),
)

export const selectCartAddress = createSelector(
  selectCartAddressArray,
  (addressList) => {
    const deliveryAddress = addressList.find((address) => address.id === 'delivery')?.attributes
    const billingAddress = addressList.find((address) => address.id === 'payment')?.attributes

    return { deliveryAddress, billingAddress }
  },
)

export const selectOrderAddress = createSelector(
  selectCartAddressArray,
  (addressList) => {
    const deliveryAddress = addressList.find((address) => address.attributes['order.address.type'] === 'delivery')?.attributes
    const billingAddress = addressList.find((address) => address.attributes['order.address.type'] === 'payment')?.attributes

    return { deliveryAddress, billingAddress }
  },
)

export const selectOrderPaymentService = createSelector(
  selectOrderServiceArray,
  (serviceList) => serviceList
    .find((service) => service.attributes['order.service.type'] === 'payment')?.attributes,
)

export const selectOrderPaymentDetails = createSelector(
  selectOrderPaymentService,
  selectOrderServiceAttributeArray,
  (paymentService, serviceAttributes): OrderPaymentData => {
    const relatedServices = serviceAttributes.filter((service) => service.attributes['order.service.attribute.parentid'] === paymentService?.['order.service.id'])
    return relatedServices.reduce((acc, service) => {
      const { 'order.service.attribute.code': code, 'order.service.attribute.value': value } = service.attributes
      return { ...acc, [code]: value }
    }, { } as OrderPaymentData)
  },
)

const selectAddressType = (
  _: CartResponse,
  __: keyof CartAddress,
  addressType: 'deliveryAddress' | 'billingAddress',
) => addressType

export const selectCartAddressAttribute = createSelector(
  selectCartAddress,
  selectAttribute,
  selectAddressType,
  (attributes, attribute, addressType) => attributes[addressType]?.[attribute]?.toString() || '',
)

export const selectSupplierTotal = createSelector(
  selectCartIncluded,
  selectSupplier,
  (included, supplier) => supplier.products
    ?.filter(isProductInclude)
    ?.reduce((productTotalPrice, { product, attributes }) => {
      if (product) {
        const totalProductQuantity = included
          ?.filter(isProductInclude)
          ?.filter((item) => item.product?.['product.id'] === product['product.id'])
          .reduce((productTotal, current) => productTotal + current.attributes['order.product.quantity'], 0)
        return productTotalPrice + totalProductQuantity * Number(attributes['order.product.price'])
      }
      return 0
    }, 0),
)

export const selectTotalCartQuantity = createSelector(selectCart, (cart) => {
  const filteredIncludedProducts = getProductIncluded(cart)

  const quantityCount = filteredIncludedProducts
    .reduce<number>((prev, { attributes, product }) => {
    const sku = product?.['product.code']
    if (!sku) {
      return prev
    }

    const quantity = attributes['order.product.quantity']

    return prev + quantity
  }, 0)

  return quantityCount
})

export const selectSupplierShipping = createSelector(
  selectCart,
  selectSupplier,
  (cart, supplier) => {
    const supplierShippingPrice = cart.included
      ?.filter(isServiceInclude)
      ?.find((include) => (
        (include.id === 'delivery' || include.attributes['order.service.type'] === 'delivery')
          && include.attributes['order.service.code'] === supplier.supplierCode
      ))?.attributes?.['order.service.costs']

    return Number(supplierShippingPrice) || 0
  },
)

export const selectSupplierProductsTotal = createSelector(
  selectCart,
  selectSupplier,
  (cart, supplier) => cart.included
    ?.filter(isProductInclude)
    ?.reduce<number>((total, product) => {
    if (product.attributes['order.product.vendor'] === supplier.supplierCode) {
      const quantity = Number(product.attributes['order.product.quantity'])
      const unitPrice = Number(product.attributes['order.product.price'])
      const price = unitPrice * quantity

      return total + price
    }

    return total
  }, 0) ?? 0,
)

export const selectSupplierBulkDiscount = createSelector(
  selectCart,
  selectSupplier,
  (cart, supplier) => supplier.products
    ?.filter(isProductInclude)
    .reduce((totalDiscount, { product }) => {
      if (!product) {
        return totalDiscount
      }

      const totalProductQuantity = getIncluded(cart)
        ?.filter(isProductInclude)
        ?.filter((item) => item.product?.['product.id'] === product['product.id'])
        .reduce((total, current) => total + current.attributes['order.product.quantity'], 0)

      const { currentDiscount } = getCurrentUpcomingDiscount(product, Number(totalProductQuantity))

      if (currentDiscount) {
        const defaultPrice = getDefaultPrice(product.price)
        const totalPrice = (Number(defaultPrice?.['price.value']) + Number(defaultPrice?.['price.rebate'])) * Number(totalProductQuantity)
        return totalDiscount + totalPrice - Number(totalProductQuantity) * (Number(currentDiscount['price.value']) + Number(currentDiscount['price.rebate']))
      }

      return totalDiscount
    }, 0) || 0,
)

export const selectSupplierDiscount = createSelector(
  selectCart,
  selectSupplier,
  (cart, supplier) => cart.included
    .filter(isProductInclude)
    .reduce<number>((prev, { attributes }) => (
    prev + (attributes['order.product.vendor'] === supplier.supplierCode ? Number(attributes['order.product.rebate']) * attributes['order.product.quantity'] : 0)
  ), 0),
)

export const selectCartAddressEmail = createSelector(
  selectCartAddress,
  ({ billingAddress, deliveryAddress }) => {
    const email = billingAddress?.['order.address.email'] || deliveryAddress?.['order.address.email']
    return email
  },
)

export const selectCartAddressFirstName = createSelector(
  selectCartAddress,
  ({ billingAddress, deliveryAddress }) => {
    const firstName = billingAddress?.['order.address.firstname'] || deliveryAddress?.['order.address.firstname']
    return firstName
  },
)

export const selectCartAddressLastName = createSelector(
  selectCartAddress,
  ({ billingAddress, deliveryAddress }) => {
    const lastName = billingAddress?.['order.address.lastname'] || deliveryAddress?.['order.address.lastname']
    return lastName
  },
)

export const selectCartAddressFullName = createSelector(
  selectCartAddressFirstName,
  selectCartAddressLastName,
  (firstName, lastName) => {
    if (firstName?.toLowerCase().includes('non logged in') || lastName?.toLowerCase().includes('non logged in')) {
      return undefined
    }

    if (firstName && lastName) {
      return `${firstName} ${lastName}`
    }

    if (firstName) {
      return firstName
    }

    if (lastName) {
      return lastName
    }

    return undefined
  },
)

export const selectCartAddressCompany = createSelector(
  selectCartAddress,
  ({ billingAddress, deliveryAddress }) => {
    const company = billingAddress?.['order.address.company'] || deliveryAddress?.['order.address.company']
    return company
  },
)
