import TagManager from 'react-gtm-module'

interface CookieBannerEventParams {
  eventName: CookieBannerEventNames
  language: string
  country: string
  customerType: 'business' | 'work' | 'home'
}

export enum CookieBannerEventNames {
  VIEW_COOKIE_BANNER = 'view_cookie_banner',
  ACCEPT_COOKIES = 'accept_cookies',
  REJECT_COOKIES = 'reject_cookies',
  MANAGE_COOKIES = 'manage_cookies',
}

export const cookieBannerEvent = ({
  eventName,
  language,
  country,
  customerType,
}: CookieBannerEventParams) => {
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      language: language.toLowerCase(),
      country: country.toLowerCase(),
      customer_type: customerType,
    },
  })
}
