import { FormValues, StepName } from '../../../components/Modals/ModalFlows/QuoteRequest/utils'
import { libraryFunctions } from '../../../lib/resources/price'
import { Currency, QUARTERLY_ORDER_VOLUME_THRESHOLDS } from '../../../utils/constants'
import { Product, ProductQuantity } from '../../../utils/types/Product'

const { getProductPrice } = libraryFunctions

type RequestForQuoteModel = {
  product: Product
  formValues: FormValues
  isQualified: boolean
  updateValues: (key: StepName) => (values: FormValues[StepName]) => void
  onSubmit: () => void
  goToNextStep: () => void
  setIsQualified: (isQualified: boolean) => void
  addToCart?: () => Promise<void>
  productQuantity?: ProductQuantity[]
  setProductQuantity?: React.Dispatch<React.SetStateAction<ProductQuantity[]>>
}

class RequestForQuote {
  product: RequestForQuoteModel['product']

  formValues: RequestForQuoteModel['formValues']

  isQualified: RequestForQuoteModel['isQualified']

  updateValues: RequestForQuoteModel['updateValues']

  onSubmit: RequestForQuoteModel['onSubmit']

  goToNextStep: RequestForQuoteModel['goToNextStep']

  setIsQualified: RequestForQuoteModel['setIsQualified']

  addToCart?: RequestForQuoteModel['addToCart']

  productQuantity?: RequestForQuoteModel['productQuantity']

  setProductQuantity?: RequestForQuoteModel['setProductQuantity']

  constructor(model: RequestForQuoteModel) {
    const {
      product,
      formValues,
      isQualified,
      updateValues,
      onSubmit,
      goToNextStep,
      setIsQualified,
      addToCart,
      productQuantity,
      setProductQuantity,
    } = model

    this.product = product
    this.formValues = formValues
    this.isQualified = isQualified
    this.updateValues = updateValues
    this.onSubmit = onSubmit
    this.goToNextStep = goToNextStep
    this.setIsQualified = setIsQualified
    this.addToCart = addToCart
    this.productQuantity = productQuantity
    this.setProductQuantity = setProductQuantity

    this.getQualifiedOrderSize = this.getQualifiedOrderSize.bind(this)
  }

  getQualifiedOrderSize() {
    const productPrice = getProductPrice(this.product)
    return Math.ceil((QUARTERLY_ORDER_VOLUME_THRESHOLDS[Currency.EUR] * 4) / productPrice)
  }
}

export default RequestForQuote
