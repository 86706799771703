import { FC } from 'react'

interface DownChevronCircleProps {
  className: string
}

const DownChevronCircle: FC<DownChevronCircleProps> = ({ className }) => (
  <svg
    className={className}
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="23"
      height="23"
      rx="11.5"
      fill="#EBF4EE"
    />
    <path
      d="M15.7302 9.34669C15.8898 9.49037 15.9191 9.7249 15.8101 9.90138L15.7627 9.96455L11.8252 14.3396C11.6707 14.5112 11.4143 14.5303 11.2372 14.3968L11.1748 14.3396L7.23731 9.96455C7.07567 9.78495 7.09023 9.50833 7.26982 9.34669C7.42947 9.20301 7.66578 9.19855 7.82984 9.32547L7.88769 9.37921L11.5009 13.3924L15.1123 9.37921C15.256 9.21956 15.4905 9.19032 15.667 9.29932L15.7302 9.34669Z"
      fill="#023512"
      stroke="#023512"
      strokeWidth="0.7"
    />
  </svg>
)

export default DownChevronCircle
