import { FC } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { Supplier } from '../utils/types/Product'
import LightningTooltip from './LightningTooltip'
import { formatPriceNumber } from '../utils/math'
import SafeTranslate from './common/SafeTranslate'
import { getCountryAndLocaleStrings, getCurrencyCode } from '../utils/locales'
import { Currency } from '../utils/constants'
import { getSupplierDeliveryDetails } from '../lib/service'

interface PriceRevealToolTipProps {
  value: number
  supplier: Supplier
  isFreeDelivery: boolean
  isImportedProduct?: boolean
  isShippingIncluded?: boolean
}

const PriceRevealToolTip: FC<PriceRevealToolTipProps> = (props) => {
  const {
    value,
    isFreeDelivery,
    supplier,
    isImportedProduct,
    isShippingIncluded = false,
  } = props

  const { t } = useTranslation('products')
  const { locale } = useRouter()

  const { country } = getCountryAndLocaleStrings(locale)
  const currencyCode = getCurrencyCode(country)
  const currency = Currency[currencyCode]
  const supplierName = supplier?.['supplier.label'] || ''
  const { freeShippingLimit } = getSupplierDeliveryDetails(supplier, country)

  const topText = isShippingIncluded
    ? t('Sales unit price includes shipping for {{ supplier }}', { supplier: supplierName })
    : t('cart:Sales unit price doesnt include shipping for {{ supplier }}', { supplier: supplierName })

  const bottomText = isShippingIncluded
    ? 'products:Without shipping {{ value }} {{ currency }}'
    : 'cart:With shipping fee distributed over selected products and quantities the sales unit price is {{ value }} {{ currency }}'

  if (isImportedProduct) {
    return (
      <LightningTooltip
        className="margin-right-spacer-half"
        content={(
          <>
            {`${t('buyerProfile:Imported products sales unit price includes shipping fee from imported supplier {{ supplier }}', { supplier: supplierName })}. `}
          </>
        )}
      />
    )
  }

  return (
    <LightningTooltip
      className="margin-right-spacer-half"
      content={(
        <>
          {`${topText}. `}
          {!isFreeDelivery
            ? (
              <>
                <br />
                <SafeTranslate
                  i18nKey={bottomText}
                  components={{ b: <b /> }}
                  values={{
                    value: formatPriceNumber(value),
                    currency,
                  }}
                />
              </>
            )
            : (
              <SafeTranslate
                i18nKey="products:You unlocked free shipping from supplier {{ supplier }} via ordering over {{ value }} {{ currency }}"
                components={{ b: <b /> }}
                values={{
                  value: formatPriceNumber(freeShippingLimit),
                  currency,
                  supplier: supplierName,
                }}
              />
            )}
        </>
      )}
    />
  )
}

export default PriceRevealToolTip
