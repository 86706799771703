import TagManager from 'react-gtm-module'

interface ImpersonationEventParams {
  superuserEmail: string
  impersonatedEmail: string
}

const createImpersonationEvent = (eventName: 'start_impersonating' | 'stop_impersonating') => (props: ImpersonationEventParams) => {
  const { superuserEmail, impersonatedEmail } = props
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      superuser_email: superuserEmail,
      impersonated_email: impersonatedEmail,
    },
  })
}

export const startImpersonatingEvent = createImpersonationEvent('start_impersonating')
export const stopImpersonatingEvent = createImpersonationEvent('stop_impersonating')
