import { FC, useRef, RefObject, Fragment, useCallback } from 'react'
import Link from 'next/link'
import { Badge, Button, Divider, Nav, Popover, Whisper } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'
import SignOutIcon from '@rsuite/icons/legacy/SignOut'
import RsuiteProfileIcon from '@rsuite/icons/legacy/Profile'
import ArchiveIcon from '@rsuite/icons/legacy/Archive'
import CubesIcon from '@rsuite/icons/legacy/Cubes'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { useAuth } from '../../../services/useAuth'
import usePersistLocale from '../../../services/usePersistLocale'
import SearchBar from './Search/Search'
import { HeaderLinks } from '../../../utils/types/misc'
import supplierDropdownLinks from '../../../utils/supplierDropdownLinks.json'
import CountryPicker from './CountryPicker/CountryPicker'
import CartNav from './CartNav'
import NavigationLink from './NavigationLink'
import DroppeLogo from '../../Icons/DroppeLogo'
import useContactModal from '../../Modals/hooks/useContactModal'
import useIsOnMobile from '../../../services/useIsOnMobile'
import { ScreenSize, LOGIN_MODAL_TRIGGERED_PLACE_KEY, LoginModalTriggeredPlace } from '../../../utils/constants'
import withRequireAuth from '../../../services/withRequireAuth'
import useUrls from '../../../services/useUrls'
import HeartIcon from '../../Icons/SavedProducts/HeartIcon'
import { isSuperUser, isSupplier } from '../../../lib/supplier'
import useLoginModal from '../../../services/useLoginModal'
import ProfileIconPicker from './ProfileIconPicker/ProfileIconPicker'
import useSavedProduct from '../../hooks/useSavedProduct'
import SignInPicker from './SignInPicker/SignInPicker'
import useBuyerCatalogs from '../../../services/useBuyerCatalogs'
import CatalogHeaderIcon from '../../Icons/CatalogHeaderIcon'
import CustomerTypeSwitcher from './CustomerTypeSwitcher'

import buttonStyles from '../../../styles/CustomButtons.module.less'
import styles from '../../../styles/Navigation.module.less'

interface NavigationProps {
  headerRef: RefObject<HTMLDivElement>
  className?: string
}

const Navigation: FC<NavigationProps> = ({ headerRef, className }) => {
  const countryPickerRef = useRef<HTMLDivElement>(null)

  const { user } = useAuth()
  const { t } = useTranslation('common')

  // TODO: Remove when proper tablet screensize breakpoint designs are implemented
  const isScreenBelowTabletHeaderBreakpoint = useIsOnMobile(ScreenSize.TABLET_HEADER_BREAKPOINT)

  const { pushT } = useUrls()

  const { openLoginModal } = useLoginModal()

  const { savedProductsCount } = useSavedProduct()
  const { orderListsCount } = useBuyerCatalogs()

  const handleIconClick = useCallback((url: string, shouldRedirect?: boolean) => {
    if (user) {
      pushT(url)
      return
    }

    // Using session storage to handle redirection logic in LoginModal
    if (shouldRedirect) {
      sessionStorage.setItem(LOGIN_MODAL_TRIGGERED_PLACE_KEY, LoginModalTriggeredPlace.headerHeart)
    }

    openLoginModal('main_header')
  }, [user, openLoginModal, pushT])

  // Feature flags - manage from Growthbook UI
  const saveProductFeatureFlag = useFeatureIsOn('product-card-heart-icon')

  const openContactModal = useContactModal(
    'main_header_modal', // contact_context deprecated, will be removed later.
    'main_header_contact_modal', // context
  )

  usePersistLocale()

  // Since rsuite v5 requires icons to be imported as whole standalone components, we need to
  // convert the JSON icon names to their corresponding components and pass them on
  const toIconComponent = (iconName: string | undefined) => {
    switch (iconName) {
      case 'profile': {
        return <RsuiteProfileIcon />
      }
      case 'sign-out': {
        return <SignOutIcon />
      }
      case 'archive': {
        return <ArchiveIcon />
      }
      case 'cubes': {
        return <CubesIcon />
      }
      default: {
        return <></>
      }
    }
  }

  const shouldDisplayDropdownMenu = isSupplier(user) || isSuperUser(user)

  const DropdownMenu = shouldDisplayDropdownMenu
    ? withRequireAuth(() => (
      <Nav
        data-testid="manage-dropdown"
        className={`${styles['dropdown-trigger-vertical-fix']}`}
        pullRight
      >
        <Nav.Menu
          title={t('Manage')}
          trigger="click"
          placement="bottomEnd"
        >
          {(supplierDropdownLinks as HeaderLinks[])?.map((linkObject) => (
            <NavigationLink
              key={linkObject.label}
              href={linkObject.href}
              translateURL={linkObject.translateURL}
              label={linkObject.label}
              icon={toIconComponent(linkObject.iconName)}
              loggedIn={linkObject.loggedIn}
              className={linkObject.className?.split(' ').map((c) => buttonStyles[c]).join(' ')}
              loggedOut={linkObject.loggedOut}
              rightDropDown={linkObject.rightDropDown}
              topDivider={linkObject.topDivider}
            />
          ))}
        </Nav.Menu>
      </Nav>
    ))
    : Fragment

  const catalogIconHover = (
    <Popover className={styles['catalog-popover-width']}>
      {t('Simplify reordering with convenient company-wide shopping lists')}
    </Popover>
  )

  return (
    <div className={`${styles['main-navigation-wrapper']} ${className}`}>
      <div className={styles['header-brand-container']}>
        <Link href="/">
          <DroppeLogo className={styles.logo} />
        </Link>
      </div>

      <SearchBar />

      <div className={styles['country-picker-nav']}>
        <CountryPicker
        // Used to ensure whisper dropdown stays fixed when
        // scrolling by setting parent container to country picker
          countryPickerRef={countryPickerRef}
        />
      </div>

      {!isScreenBelowTabletHeaderBreakpoint && (
        <div className={styles['nav-login-text']}>
          <SignInPicker
            onIconClick={handleIconClick}
            topHeaderRef={countryPickerRef}
          />
        </div>
      )}

      {user === null
        ? (
          <Button
            appearance="ghost"
            className={`
                  ${buttonStyles['custom-button']}
                  ${buttonStyles['button-transparent']}
                  ${styles['header-nav-button']}
                  margin-left-spacer
                `}
            onClick={() => openContactModal()}
          >
            {t('Contact')}
          </Button>
        )
        : <DropdownMenu />}

      <div className={styles['icons-wrapper']}>
        <Button
          appearance="link"
          className={`${styles['nav-icon']} ${styles['catalog-icon']}`}
          onClick={() => handleIconClick('/my-catalogs', true)}
        >
          <Whisper
            placement="bottomEnd"
            controlId="control-id-hover"
            trigger="hover"
            speaker={catalogIconHover}
          >
            <Badge content={orderListsCount || false}>
              <CatalogHeaderIcon />
            </Badge>
          </Whisper>
        </Button>

        {saveProductFeatureFlag && (
          <Button
            appearance="link"
            className={`${styles['nav-icon']} ${styles['heart-icon']}`}
            onClick={() => handleIconClick('/my-products', true)}
          >
            <Badge content={savedProductsCount || false}>
              <HeartIcon
                className={styles['heart-icon']}
                variant="dark"
              />
            </Badge>
          </Button>
        )}

        <Button
          appearance="link"
          className={`${styles['nav-icon']} ${styles['profile-icon']}`}
        >
          <ProfileIconPicker
            onIconClick={handleIconClick}
            topHeaderRef={countryPickerRef}
          />
        </Button>

        <Button
          appearance="link"
          className={`${styles['nav-icon']} ${styles['cart-icon']}`}
        >
          <CartNav
            headerRef={headerRef}
          />
        </Button>
      </div>

      <Divider
        vertical
        className={styles['vertical-divider']}
      />

      <CustomerTypeSwitcher />

    </div>
  )
}

export default Navigation
