import { Dispatch, FC, ReactNode, SetStateAction } from 'react'
import { Button, FlexboxGrid, Stack } from 'rsuite'
import CloseIcon from '@rsuite/icons/Close'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'

import useUrls from '../../../services/useUrls'
import { useCart } from '../../Cart/CartProvider'
import { selectCartProductsUnique } from '../../Cart/selectors'
import { formatPriceNumber } from '../../../utils/math'
import { getProductCurrency } from '../../../lib/product'
import { Product } from '../../../utils/types/Product'
import useIsOnMobile from '../../../services/useIsOnMobile'

import styles from '../../../styles/CartPopup.module.less'

export interface CartPopupWrapperProps {
  closePopover?: () => void
  isForHover?: boolean
  setIsCartPopupHovered?: Dispatch<SetStateAction<boolean>>
  children: ReactNode
  displayTitle?: boolean
  closeModal?: () => void
}

const CartPopupWrapper: FC<CartPopupWrapperProps> = (props) => {
  const {
    isForHover,
    closePopover,
    children,
    displayTitle = true,
    setIsCartPopupHovered,
    closeModal,
  } = props

  const { cartState: { cart } } = useCart()
  const products = cart ? selectCartProductsUnique(cart) : []
  const { t } = useTranslation('common')
  const { urlT } = useUrls()
  const isOnMobile = useIsOnMobile()

  const totalCartPrice = formatPriceNumber(Number(cart?.data?.attributes['order.price']))
  const currency = getProductCurrency(products?.[0].product as Product)

  const title = t('cart:{{count}} products in cart', { count: products.length })

  const handleClose = () => {
    if (closePopover) closePopover()
    if (setIsCartPopupHovered) setIsCartPopupHovered(false)
  }

  return (
    <>
      <Stack alignItems="baseline">
        {displayTitle && (
          <Stack.Item grow={1}>
            <h5 className="margin-bottom-spacer-tripple">{title}</h5>
          </Stack.Item>
        )}
        <Stack.Item>
          {!isForHover && (
          <CloseIcon
            className="cursor-pointer"
            onClick={handleClose}
          />
          )}
        </Stack.Item>
      </Stack>
      {children}
      <FlexboxGrid
        justify="space-between"
        align="middle"
        className={styles['cart-popup-footer']}
      >
        <FlexboxGrid.Item>
          <b className="nowrap-text">
            {t('Total')}
            {' '}
            {totalCartPrice}
            {' '}
            {currency}
          </b>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item className={isOnMobile ? '' : 'margin-left-spacer-double'}>
          <Link href={urlT('/cart')}>
            <Button
              className={styles['checkout-button']}
              appearance="primary"
              size="lg"
              block
              onClick={() => closeModal && closeModal()}
              data-testid="checkout-button"
            >
              {t('cart:View cart')}
            </Button>
          </Link>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item className={styles['continue-button']}>
          <Button
            appearance="ghost"
            size="lg"
            block
            onClick={() => closeModal && closeModal()}
          >
            {t('checkout:Continue shopping')}
          </Button>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  )
}

export default CartPopupWrapper
