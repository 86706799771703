import { ProvideAuthI } from '../../../utils/types/auth'

class Auth {
  user: ProvideAuthI['user']

  isImpersonated: ProvideAuthI['isImpersonated']

  skuPrefix: ProvideAuthI['skuPrefix']

  consumptionTier: ProvideAuthI['consumptionTier']

  isLoadingUser: ProvideAuthI['isLoadingUser']

  isLoadingResources: ProvideAuthI['isLoadingResources']

  checkUser: ProvideAuthI['checkUser']

  setLoadingResources: ProvideAuthI['setLoadingResources']

  clearUserData: ProvideAuthI['clearUserData']

  login: ProvideAuthI['login']

  impersonate: ProvideAuthI['impersonate']

  logout: ProvideAuthI['logout']

  impersonateLeave: ProvideAuthI['impersonateLeave']

  refresh: ProvideAuthI['refresh']

  registerCall: ProvideAuthI['registerCall']

  sendPasswordResetEmail: ProvideAuthI['sendPasswordResetEmail']

  confirmPasswordReset: ProvideAuthI['confirmPasswordReset']

  isInvalidSessionModalOpen: ProvideAuthI['isInvalidSessionModalOpen']

  openInvalidSessionModal: ProvideAuthI['openInvalidSessionModal']

  closeInvalidSessionModal: ProvideAuthI['closeInvalidSessionModal']

  useRefreshCallback: ProvideAuthI['useRefreshCallback']

  constructor(auth: ProvideAuthI) {
    this.user = auth.user
    this.isImpersonated = auth.isImpersonated
    this.skuPrefix = auth.skuPrefix
    this.consumptionTier = auth.consumptionTier
    this.isLoadingUser = auth.isLoadingUser
    this.isLoadingResources = auth.isLoadingResources
    this.checkUser = auth.checkUser
    this.setLoadingResources = auth.setLoadingResources
    this.clearUserData = auth.clearUserData
    this.login = auth.login
    this.impersonate = auth.impersonate
    this.logout = auth.logout
    this.impersonateLeave = auth.impersonateLeave
    this.refresh = auth.refresh
    this.registerCall = auth.registerCall
    this.sendPasswordResetEmail = auth.sendPasswordResetEmail
    this.confirmPasswordReset = auth.confirmPasswordReset
    this.isInvalidSessionModalOpen = auth.isInvalidSessionModalOpen
    this.openInvalidSessionModal = auth.openInvalidSessionModal
    this.closeInvalidSessionModal = auth.closeInvalidSessionModal
    this.useRefreshCallback = auth.useRefreshCallback
  }
}

export default Auth
