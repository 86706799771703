import { Translate } from 'next-translate'

import { getPrices } from './getPrices'
import { getPricePerPieceUnit, getSalesUnit, getTotalPieceCountString } from './salesUnit'
import { formatPriceNumber } from '../utils/math'
import { CurrencySymbol, Customer, CustomerRelationList, Order, Product } from '../utils/types/Product'
import { ActiveCountryId } from '../utils/constants'
import { getLargePreview } from '../utils/images'
import { getOrderProductMainId, getOrderProductQuantity } from './OrderHistory'
import { GenericOrderData, OrderResponse } from '../utils/types/Order'
import { SavedProductData, TableColumnSizes } from '../utils/types/savedProduct'
import { getProductIncluded } from '../utils/types/guards/Order'
import { getProductName } from './text'
import { CustomerResponse } from '../utils/types/customer'
import { selectNormalizedCustomer } from '../components/BuyerProfile/Account/selectors'

export type CustomerListIds = Pick<CustomerRelationList, 'customer.lists.id' | 'customer.lists.refid' | 'customer.lists.parentid' | 'customer.lists.type'>

export const SavedProductTableSizes: TableColumnSizes = {
  image: 120,
  productSkuSupplier: 230,
  referencePrice: 140,
  moq: 200,
  savedProduct: 190,
  reorder: 280,
}

export const PreviousOrdersTableSizes: TableColumnSizes = {
  image: 120,
  productSkuSupplier: 220,
  referencePrice: 150,
  moq: 150,
  savedProduct: 160,
  viewOrder: 120,
  reorder: 240,
}

export const getCustomerIdsByOrderedProduct = (
  product: Product,
  orders: OrderResponse | Order[],
) => {
  if (Array.isArray(orders)) {
    const currentProducts = orders
      .flatMap((order) => order['order/product'])
      .filter((orderedProduct) => (
        product['product.id'] === getOrderProductMainId(orderedProduct)
      ))

    const customerIdsFromProductOrders = orders
      .filter((order) => (order['order/product']
        .some((orderedProduct) => (
          currentProducts
            .map((curr) => curr['order.product.id'])
            .includes(orderedProduct['order.product.id'])
        ))
      ))
      .map((orderForProduct) => orderForProduct['order.customerid'])

    return Array.from(new Set(customerIdsFromProductOrders))
  }

  const currentProducts = orders
    ? getProductIncluded(orders)
      .filter((ele) => (
        getOrderProductMainId(ele.attributes) === product['product.id']
      ))
    : []

  const customerIdsFromProductOrders = orders?.data
    ?.filter(({ relationships }: GenericOrderData<'order'>) => (
      relationships['order/product']?.data
        .some((orderProduct) => (
          currentProducts
            .map((curr) => curr.id)
            .includes(orderProduct.id)
        ))
    ))
    .map((orderForProduct) => orderForProduct.attributes['order.customerid'])

  return Array.from(new Set(customerIdsFromProductOrders))
}

export const getReferencePriceText = (
  product: Product,
  referencePrice: number,
  currency: CurrencySymbol,
  t: Translate,
) => {
  const price = formatPriceNumber(referencePrice)
  const productPricingUnit = getPricePerPieceUnit(product)

  return `${price} ${currency} / ${t(productPricingUnit, { count: 1 })}`
}

const getTableData = (
  product: Product,
  userSavedProducts?: Pick<
  CustomerRelationList,
  'customer.lists.id' | 'customer.lists.refid' | 'customer.lists.parentid'
  >[],
) => {
  const customerList = userSavedProducts?.find(
    (ele) => ele['customer.lists.refid'] === product['product.id'],
  )
  const listId = customerList?.['customer.lists.id']

  return {
    imageUrl: getLargePreview(product.media?.[0]),
    productName: getProductName(product) ?? '',
    productCode: product['product.code'] ?? '',
    supplierName: product.supplier?.[0]?.['supplier.label'] ?? '',
    product,
    sortBy: listId ? parseInt(listId, 10) : 0,
    parentId: customerList?.['customer.lists.parentid'],
  }
}

export const getSavedProductsTableData = (
  products: Product[],
  userSavedProducts: CustomerListIds[],
) => products.map((product) => {
  const tableData = getTableData(product, userSavedProducts)

  return {
    ...tableData,
    customerIds: [tableData.parentId],
  } as SavedProductData
})

export const getPreviousOrderData = (
  products: Product[],
  orderHistory: OrderResponse | Order[] | undefined,
) => products.map((product) => {
  const customerIds = orderHistory ? getCustomerIdsByOrderedProduct(product, orderHistory) : []

  if (Array.isArray(orderHistory)) {
    const orderProduct = orderHistory
      .flatMap((order) => order['order/product'])
      .find((orderedProduct) => (
        product.id === getOrderProductMainId(orderedProduct)
      ))

    const orderForProduct = orderHistory
      .find((order) => (order['order/product']
        .find((orderedProduct) => orderProduct === orderedProduct)
      ))

    return {
      ...getTableData(product),
      orderId: orderForProduct?.id,
      parentId: orderForProduct?.['order.customerid'],
      orderedQuantity: getOrderProductQuantity(product, orderHistory),
      sortBy: Number(orderForProduct?.id) || 0,
      customerIds,
    }
  }

  const currentProduct = orderHistory
    ? getProductIncluded(orderHistory)
      .find((ele) => (
        getOrderProductMainId(ele.attributes) === product['product.id']
      ))
    : undefined

  const orderForProduct = orderHistory?.data?.find(({ relationships }) => relationships['order/product']?.data
    .find((ele) => (ele.id === currentProduct?.id)))

  return {
    ...getTableData(product),
    orderId: orderForProduct?.id,
    orderedQuantity: orderHistory ? getOrderProductQuantity(product, orderHistory) : undefined,
    sortBy: Number(orderForProduct?.id) || 0,
    parentId: orderForProduct?.attributes['order.customerid'],
    customerIds,
  }
})

export const getMoq = (product: Product, t: Translate, orderedQuantity?: number) => {
  const { moq } = getPrices(product, 1)
  const getProductSalesUnit = (count: number) => t(getSalesUnit(product), { count })
  const salesUnit = getSalesUnit(product)
  const salesUnitDescription = getTotalPieceCountString(product, t, salesUnit, orderedQuantity)

  return orderedQuantity
    ? `${orderedQuantity} ${getProductSalesUnit(moq)} ${salesUnitDescription}`
    : `${moq} ${getProductSalesUnit(moq)} ${salesUnitDescription}`
}

export const getMoqWithSalesUnit = (product: Product, t: Translate) => {
  const { moq } = getPrices(product, 1)
  const getProductSalesUnit = (count: number) => t(getSalesUnit(product), { count })

  return `${moq} ${getProductSalesUnit(moq)}`
}

export const isFavoriteProduct = (
  product: CustomerRelationList | CustomerListIds,
) => product['customer.lists.type'] === 'favorite'

export const getCatalogProductQuantity = (quantity: Record<string, number>) => quantity['']
?? Object.values<number>(quantity).reduce((a, b) => a + b, 0)

const getAdminName = (admin: Customer) => (
  admin['customer.firstname'] && admin['customer.lastname']
    ? `${admin['customer.firstname']} ${admin['customer.lastname']}`
    : admin['customer.firstname']
    || admin['customer.lastname']
    || admin['customer.email']
)

const getName = (customerId: string, customers: CustomerResponse) => {
  const {
    fullName,
    email,
  } = selectNormalizedCustomer(customers, customerId)

  return fullName || email || ''
}
export const getCustomerName = (
  customerId?: string,
  customers?: CustomerResponse | null,
  admin?: Customer,
) => {
  if (customers && customerId) {
    return getName(customerId, customers)
  }

  return admin ? getAdminName(admin) : ''
}
