import useTranslation from 'next-translate/useTranslation'
import Script from 'next/script'
import { useEffect, useRef, useState } from 'react'
import { Button } from 'rsuite'
import { useRouter } from 'next/router'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import CookieManageOptions from './CookieManageOptions'
import { getCountryAndLocaleStrings } from '../../utils/locales'
import { CookieBannerEventNames, cookieBannerEvent } from '../../utils/analytics/cookieBannerEvents'
import { createCookie } from '../../utils/cookies'
import CustomerTypeSelection, { CustomerType } from '../CustomerTypeSelection'
import selectCustomerTypeEvent from '../../utils/analytics/selectCustomerTypeEvent'
import { useAuth } from '../../services/useAuth'
import { useCustomer } from '../BuyerProfile/Account/CustomerProvider'

import styles from '../../styles/CookieBanner.module.less'
import buttonStyles from '../../styles/CustomButtons.module.less'

const CookieBanner = () => {
  const [selectedCustomerType, setSelectedCustomerType] = useState<CustomerType>('business')
  const [shouldShowManageOptions, setShouldShowManageOptions] = useState(false)

  const { t } = useTranslation('common')
  const { locale: countryAndLang } = useRouter()
  const cookieBannerRef = useRef<HTMLDivElement>(null)
  const { user, refresh } = useAuth()
  const { updateCustomerType } = useCustomer()

  const { locale, country } = getCountryAndLocaleStrings(countryAndLang)

  const showCookieBannerFeatureFlag = useFeatureIsOn('enable-cookie-consent-banner')

  const handleRejectOnClick = () => {
    // Analytics event
    // Other actions are handled by a 3rd party library
    cookieBannerEvent({
      eventName: CookieBannerEventNames.REJECT_COOKIES,
      country,
      language: locale,
      customerType: selectedCustomerType,
    })
  }

  const handleAcceptOnClick = async () => {
    // Analytics events
    // Other actions are handled by a 3rd party library
    cookieBannerEvent({
      eventName: CookieBannerEventNames.ACCEPT_COOKIES,
      country,
      language: locale,
      customerType: selectedCustomerType,
    })
    selectCustomerTypeEvent({ context: 'cookie_banner' })

    createCookie('customerType', selectedCustomerType, 365)

    // Update user data in database if user is logged in
    if (user) {
      await updateCustomerType(selectedCustomerType)
      refresh()
    }
  }

  const handleManageOptionsOnClick = () => {
    setShouldShowManageOptions(true)

    // Analytics event
    cookieBannerEvent({
      eventName: CookieBannerEventNames.MANAGE_COOKIES,
      country,
      language: locale,
      customerType: selectedCustomerType,
    })
  }

  // Analytics event
  useEffect(() => {
    if (cookieBannerRef.current?.style.display !== 'none') {
      cookieBannerEvent({
        eventName: CookieBannerEventNames.VIEW_COOKIE_BANNER,
        country,
        language: locale,
        customerType: selectedCustomerType,
      })
    }
  }, [cookieBannerRef.current?.style.display])

  const handleCustomerTypeOnClick = (customerType: CustomerType) => {
    setSelectedCustomerType(customerType)
  }

  return (showCookieBannerFeatureFlag
    ? (
      <>
        <div
          id="cookies-eu-banner"
          style={{ display: 'none' }}
          ref={cookieBannerRef}
        >
          <div
            aria-hidden="true"
            className={`rs-modal-backdrop ${styles.backdrop} ${styles['banner-backdrop']}`}
          />
          <div className={styles['banner-wrapper']}>
            <div className={styles.banner}>
              <div className={styles['banner-body']}>
                <CustomerTypeSelection
                  onCustomerTypeSelect={handleCustomerTypeOnClick}
                />
              </div>

              <div className={styles['banner-footer']}>
                <h6>{t('Our website uses cookies')}</h6>

                {shouldShowManageOptions ? (
                  <CookieManageOptions />
                ) : (
                  <p>{t('We use cookies to enhance your experience and display prices inclusive or exclusive of VAT')}</p>
                )}

                <div className={styles['banner-footer-buttons']}>
                  {/* NOTE: As 3rd party library is listening to id click */}
                  <Button
                    id="cookies-eu-reject"
                    className={shouldShowManageOptions ? styles['reject-button'] : 'hide-element'}
                    appearance="subtle"
                    onClick={handleRejectOnClick}
                  >
                    {t('Reject')}
                  </Button>
                  {!shouldShowManageOptions && (
                  <Button
                    appearance="subtle"
                    onClick={handleManageOptionsOnClick}
                  >
                    {t('Manage options')}
                  </Button>
                  )}
                  {/* As 3rd party library is listening to id click */}
                  <Button
                    type="button"
                    appearance="primary"
                    id="cookies-eu-accept"
                    onClick={handleAcceptOnClick}
                  >
                    {t('Accept')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Script
          src="/scripts/cookie-eu-banner.js"
          strategy="afterInteractive"
          onLoad={() => {
          // @ts-ignore
          // eslint-disable-next-line no-new
            new CookiesEuBanner(() => {
            }, true, false) // (function, waitAccept, useLocalStorage)
          }}
        />
      </>
    )
    : null)
}

export default CookieBanner
