import { Col, FlexboxGrid, Row } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'
import { FC, ReactNode } from 'react'
import Link from 'next/link'

import SatisfactionGuarantee from '../../SatisfactionGuarantee'
import useInternalTraffic from '../../hooks/useInternalTraffic'
import InternalTrafficButton from '../../InternalTrafficButton'
import useUrls from '../../../services/useUrls'
import DroppeLogo from '../../Icons/DroppeLogo'
import OrderTimeIcon from '../../Icons/OrderTimeIcon'
import ClockIcon from '../../Icons/ClockIcon'
import CarIcon from '../../Icons/CarIcon'
import { ScreenSize, TOTAL_SATISFIED_CUSTOMERS } from '../../../utils/constants'
import useIsOnMobile from '../../../services/useIsOnMobile'

import styles from '../../../styles/Header.module.less'

interface NavItemProps {
  children: ReactNode
  text: string
}

const NavItem: FC<NavItemProps> = ({ children, text }) => {
  const { t } = useTranslation('checkout')
  const isOnMobile = useIsOnMobile()

  return (
    <FlexboxGrid.Item className={`margin-right-spacer ${isOnMobile ? '' : 'margin-top-spacer'}`}>
      {children}
      {text && <span className="margin-left-spacer">{t(text, { count: TOTAL_SATISFIED_CUSTOMERS })}</span>}
    </FlexboxGrid.Item>
  )
}

const CheckoutHeader = () => {
  const { isInternalTraffic } = useInternalTraffic()
  const { urlT } = useUrls()
  const isOnTabletBreakpoint = useIsOnMobile(ScreenSize.TABLET_HEADER_BREAKPOINT)
  const isOnTablet = useIsOnMobile(ScreenSize.lg)
  const isOnMobile = useIsOnMobile(ScreenSize.md)

  return (
    <header className={`${styles.header} ${styles['checkout-header']} hide-on-print`}>
      <Row className={isOnTablet ? '' : 'max-width-lg'}>
        <FlexboxGrid
          align="middle"
          justify="space-between"
          className={styles['flex-grid']}
        >
          <FlexboxGrid.Item
            as={Col}
            lg={5}
            xl={6}
            className={styles['logo-col']}
          >
            <Link href={urlT('/products')}>
              <DroppeLogo alignment="left" />
            </Link>
          </FlexboxGrid.Item>

          <FlexboxGrid
            justify="space-between"
            className={styles['icons-col']}
            align="middle"
          >
            {!isOnMobile && (
            <NavItem text="">
              <SatisfactionGuarantee variant="dark" />
            </NavItem>
            )}
            <NavItem text="Quick delivery">
              <CarIcon />
            </NavItem>
            {!isOnMobile && (
            <NavItem text="cart:Pay by Card or Invoice">
              <ClockIcon />
            </NavItem>
            )}
            <NavItem text="common:Over {{count}} satisfied customers">
              <OrderTimeIcon className={styles['order-time-icon']} />
            </NavItem>

            {isInternalTraffic && !isOnTabletBreakpoint && (
              <NavItem text="">
                <InternalTrafficButton />
              </NavItem>
            )}
          </FlexboxGrid>
        </FlexboxGrid>
      </Row>
    </header>
  )
}

export default CheckoutHeader
