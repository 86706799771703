import { FC } from 'react'

import {
  DIM_MID_LIGHT_COLOR,
  DIM_MID_COLOR,
  NEUTRAL_GRAY,
  DARK_GREY_COLOR,
} from '../../utils/constants'

interface CustomerTypeBusinessIconProps {
  active?: boolean
}

const CustomerTypeBusinessIcon: FC<CustomerTypeBusinessIconProps> = ({ active = false }) => (
  <svg
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="0.5"
      width="48"
      height="48"
      rx="24"
      fill={active ? DIM_MID_LIGHT_COLOR : NEUTRAL_GRAY}
    />
    <path
      d="M32 19.5H16C14.8954 19.5 14 20.3954 14 21.5V31.5C14 32.6046 14.8954 33.5 16 33.5H32C33.1046 33.5 34 32.6046 34 31.5V21.5C34 20.3954 33.1046 19.5 32 19.5Z"
      stroke={active ? DIM_MID_COLOR : DARK_GREY_COLOR}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28 33.5V17.5C28 16.9696 27.7893 16.4609 27.4142 16.0858C27.0391 15.7107 26.5304 15.5 26 15.5H22C21.4696 15.5 20.9609 15.7107 20.5858 16.0858C20.2107 16.4609 20 16.9696 20 17.5V33.5"
      stroke={active ? DIM_MID_COLOR : DARK_GREY_COLOR}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CustomerTypeBusinessIcon
