import { FC } from 'react'

import {
  DIM_MID_LIGHT_COLOR,
  DIM_MID_COLOR,
  NEUTRAL_GRAY,
  DARK_GREY_COLOR,
} from '../../utils/constants'

interface CustomerTypeWorkIconProps {
  active?: boolean
}

const CustomerTypeWorkIcon: FC<CustomerTypeWorkIconProps> = ({ active = false }) => (
  <svg
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="0.5"
      width="48"
      height="48"
      rx="24"
      fill={active ? DIM_MID_LIGHT_COLOR : NEUTRAL_GRAY}
    />
    <path
      d="M26.7013 19.8036C26.5181 19.9906 26.4155 20.2419 26.4155 20.5036C26.4155 20.7654 26.5181 21.0167 26.7013 21.2036L28.3013 22.8036C28.4882 22.9869 28.7396 23.0895 29.0013 23.0895C29.2631 23.0895 29.5144 22.9869 29.7013 22.8036L33.4713 19.0336C33.9742 20.1448 34.1264 21.3829 33.9078 22.5828C33.6892 23.7827 33.11 24.8875 32.2476 25.7499C31.3852 26.6124 30.2804 27.1915 29.0805 27.4101C27.8806 27.6287 26.6425 27.4765 25.5313 26.9736L18.6213 33.8836C18.2235 34.2815 17.6839 34.505 17.1213 34.505C16.5587 34.505 16.0191 34.2815 15.6213 33.8836C15.2235 33.4858 15 32.9462 15 32.3836C15 31.821 15.2235 31.2815 15.6213 30.8836L22.5313 23.9736C22.0285 22.8625 21.8762 21.6244 22.0949 20.4245C22.3135 19.2246 22.8926 18.1198 23.755 17.2574C24.6175 16.3949 25.7223 15.8158 26.9222 15.5972C28.1221 15.3786 29.3601 15.5308 30.4713 16.0336L26.7113 19.7936L26.7013 19.8036Z"
      stroke={active ? DIM_MID_COLOR : DARK_GREY_COLOR}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CustomerTypeWorkIcon
