import { CustomerResponse } from '../../../utils/types/customer'

class Customer {
  customer: CustomerResponse

  constructor(customer: CustomerResponse) {
    this.customer = customer
  }
}

export default Customer
