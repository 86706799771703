import TagManager from 'react-gtm-module'

interface PageNotFoundEventParams {
  page_path: string
  country: string
  language: string
}

const pageNotFoundEvent = ({ page_path, country, language }: PageNotFoundEventParams) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'page_not_found',
      page_path,
      country,
      language,
    },
  })
}

export default pageNotFoundEvent
