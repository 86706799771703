import Capitalize from 'lodash/capitalize'
import sortBy from 'lodash/sortBy'
import { Translate } from 'next-translate'

import { PaymentData, QuantityInformation } from '../utils/types/Cart'
import { removeDuplicates } from '../utils/util'
import { getBackInStockDate, getIsImportedSupplier, getProductManualStockLimit } from './product'
import {
  SingleOrderResponse,
  CartAddress,
  OrderInclude,
  OrderProductInclude,
  OrderAddressInclude,
  OrderServiceInclude,
  RelationshipObject,
  WithSuppliers, OrderResponse, CartProduct,
} from '../utils/types/Order'
import { getOrderProductRelationships, getProductIncluded, getServiceIncluded, isProductInclude } from '../utils/types/guards/Order'
import { OrderSupplierProducts } from '../utils/types/OrderHistory'
import { Order, Product } from '../utils/types/Product'
import { getOrderServiceAttriutes } from './service'
import { getPrices } from './getPrices'

export const isOrderProduct = (include: OrderInclude): include is OrderProductInclude<'order'> => (
  include.type === 'order/product'
)

export const isOrderAddress = (include: OrderInclude): include is OrderAddressInclude<'order'> => (
  include.type === 'order/address'
)

export const isOrderService = (include: OrderInclude): include is OrderServiceInclude<'order'> => (
  include.type === 'order/service'
)

export const getOrderId = (order: SingleOrderResponse): string | null => (
  order.data.attributes['order.id']
)

export const getOrderPaymentData = (
  orderData: SingleOrderResponse | undefined,
  relationships?: RelationshipObject<'basket.service'> | RelationshipObject<'order/service'>,
): PaymentData => {
  const serviceIds = relationships?.data.map(({ id }) => id)

  const paymentServices = orderData ? getServiceIncluded(orderData) : []
  const payment = paymentServices.find(({ type, id, attributes }) => (
    // Only check to match ids if relationships are passed
    (serviceIds ? serviceIds?.includes(id) : true)
      && (type === 'order/service' || 'basket.service')
      && attributes['order.service.type'] === 'payment'
  ))
  const reference = orderData?.data.attributes?.['order.customerref'] || ''
  const orderServiceAttributes = getOrderServiceAttriutes(payment!)

  return {
    reference,
    ...orderServiceAttributes,
  }
}

export const getOrderAddressData = (
  relationships: RelationshipObject<'order/address'> | undefined,
  addressIncludes: OrderAddressInclude[] | undefined,
): {
  deliveryAddress: CartAddress | undefined
  billingAddress: CartAddress | undefined
} => {
  const addressIds = relationships?.data.map(({ id }) => id)

  const getAddress = (addressType: 'delivery' | 'payment') => addressIncludes?.find(({ id, attributes }) => (
    addressIds?.includes(id) && attributes['order.address.type'] === addressType
  ))?.attributes

  const deliveryAddress = getAddress('delivery')
  const billingAddress = getAddress('payment')

  return { deliveryAddress, billingAddress }
}

export const getSupplierList = (
  relationships: RelationshipObject<'order/product'> | undefined,
  productIncludes: OrderProductInclude[] | undefined,
  t: Translate,
): string => {
  const productIds = relationships?.data.map(({ id }) => id)

  const suppliers = productIncludes?.filter(
    ({ type, id }) => productIds?.includes(id)
          && type === 'order/product',
  )
    .map((item) => (item.attributes['order.product.type'] === 'external'
      ? `${item.attributes['order.product.vendor']} (${t('products:imported')})`
      : item.attributes['order.product.vendor']))
  const suppliersList = [...new Set(suppliers)].map((supplierName) => Capitalize(supplierName))

  return suppliersList.join(', ')
}

export const getProductIdsInOrder = (cartInclude: OrderInclude[]): string[] => [...new Set(
  cartInclude
    ?.filter(isProductInclude)
    ?.map((item) => (item.attributes['order.product.type'] === 'select'
      ? item.attributes['order.product.parentproductid']
      : item.attributes['order.product.productid'])) || [],
)]

export const getOrderSupplierGroupByProducts = (
  orderResource: WithSuppliers<SingleOrderResponse>,
  showUnavailable: boolean = false,
): OrderSupplierProducts => {
  const filteredIncludedProducts = getProductIncluded(orderResource) || []

  const isProductMatchCartSupplier = (supplierCode: string) => (orderItem: OrderProductInclude) => (
    orderItem.attributes['order.product.vendor'] === supplierCode
  )

  const underMoqProductList: OrderProductInclude[] = []
  const unavailableProductList: OrderProductInclude[] = []

  const totalQuantityList = filteredIncludedProducts
    .reduce<Record<string, number>>((prev, { attributes, product }) => {
    const sku = product?.['product.code']
    if (!sku) return prev

    const quantity = attributes['order.product.quantity']
    const prevQuantity = prev[sku] || 0

    return { ...prev, [sku]: quantity + prevQuantity }
  }, {})

  const filteredProductsUnderMOQ = filteredIncludedProducts
    ?.filter((product) => {
      if (!product.product && !showUnavailable) { // Product is not available
        unavailableProductList.push({
          ...product,
        })
        return false
      }

      const sku = product.product?.['product.code'] || product.attributes['order.product.prodcode']
      const totalProductQuantity = totalQuantityList[sku]
      const moq = product.product ? getPrices(product.product, totalProductQuantity).moq : 1

      if (totalProductQuantity < moq && !showUnavailable) {
        underMoqProductList.push(product)
        return false
      }

      return true
    })

  const suppliers = removeDuplicates(
    filteredProductsUnderMOQ?.map(
      (item) => item.attributes['order.product.vendor'],
    ) ?? [],
  )

  const isUniqueByProductId = <P extends OrderProductInclude>(
    product: P,
    index: number,
    self: P[],
  ) => (
      index === self.findIndex((comparedProduct) => {
        // If full product is available, compare by that id
        if (product.product) {
          return product.product?.['product.id'] === comparedProduct.product?.['product.id']
        }
        // If parentproductid is available, it's a variant so compare by main id
        if (product.attributes['order.product.parentproductid']) {
          return product.attributes['order.product.parentproductid'] === comparedProduct.attributes['order.product.parentproductid']
        }

        // Otherwise default to comparing product id
        return product.attributes['order.product.productid'] === comparedProduct.attributes['order.product.productid']
      })
    )

  const supplierMappedProducts = suppliers.map((supplierCode) => ({
    supplierCode,
    supplierInfo: orderResource.suppliers?.find((supplier) => supplier['supplier.code'] === supplierCode),
    totalProductCount: filteredProductsUnderMOQ
      ?.filter(isProductMatchCartSupplier(supplierCode))
      .reduce((total, item) => total + item.attributes['order.product.quantity'], 0) || 0,
    products: filteredProductsUnderMOQ
      ?.filter(isProductMatchCartSupplier(supplierCode))
      .filter(isUniqueByProductId),
  }))

  return {
    ...orderResource,
    supplierMappedProducts: sortBy(supplierMappedProducts, (
      supp,
    ) => getIsImportedSupplier(supp.products)),
    underMoqProductList,
    unavailableProductList,
  }
}

export const getOrderProductSbd = (product: Product, attributes: CartProduct) => (
  product.product?.find(
    (prod) => prod['product.code'] === attributes?.['order.product.prodcode'],
  )?.['stock.dateback'])

export const getOrderProductMsl = (product: Product, attributes: CartProduct) => (
  product.product?.find(
    (prod) => prod['product.code'] === attributes?.['order.product.prodcode'],
  )?.['product/property']?.find(
    (prop) => prop['product.property.type'] === 'manual-stock-limit',
  )?.['product.property.value'])

export const getOrderProductExactStock = (product: Product, attributes: CartProduct) => (
  product.product?.find(
    (prod) => prod['product.code'] === attributes?.['order.product.prodcode'],
  )?.['stock.stocklevel'])

export const getOrderAddressObject = (
  orderResponse: SingleOrderResponse,
): OrderAddressInclude | undefined => orderResponse.included
  ?.filter(isOrderAddress)
  ?.find((include: OrderAddressInclude) => include.type === 'order/address')

export const getOrderProductsQuantity = (
  cart: WithSuppliers<SingleOrderResponse>,
): QuantityInformation[] => {
  const result: Record<string, QuantityInformation> = {}
  const orderProducts = getProductIncluded(cart) || []

  orderProducts.forEach(({ product, attributes }) => {
    if (!product) return
    const quantity = attributes?.['order.product.quantity']
    const sku = attributes?.['order.product.prodcode']
    const supplier = product.supplier[0]
    const currSupplierCode = supplier['supplier.code']

    const backInStockDate = getBackInStockDate(product)
                            ?? getOrderProductSbd(product, attributes) ?? null
    const manualStockLimit = Number(getProductManualStockLimit(product))
                            || Number(getOrderProductMsl(product, attributes))
    const exactStockLevel = getOrderProductExactStock(product, attributes) ?? null

    if (sku in result) {
      result[sku].quantity += quantity
      return
    }

    result[sku] = {
      product,
      quantity,
      supplierCode: currSupplierCode,
      manualStockLimit,
      exactStockLevel: exactStockLevel !== null ? Number(exactStockLevel) : null,
      backInStockDate: result[sku]?.backInStockDate || backInStockDate,
    }
  })

  return Object.values(result)
}

export const getOrderProductMainId = (product: CartProduct): string => (
  product?.['order.product.parentproductid']
    ? product?.['order.product.parentproductid']
    : product?.['order.product.productid']
)

export const getOrderProductIds = (data: OrderResponse | Order[]): string[] => {
  if (Array.isArray(data)) {
    return data
      .flatMap((order) => order['order/product'])
      .map(getOrderProductMainId)
  }
  const includedProducts = getProductIncluded(data)
  return includedProducts
    ?.map((ele) => getOrderProductMainId(ele.attributes))
}

export const getCustomerOrderProductIds = (data: OrderResponse, customerId: string): string[] => {
  const orders = data.data.filter((order) => order.attributes['order.customerid'] === customerId)
  const relationships = orders.flatMap((order) => getOrderProductRelationships(order))
  const productIds = relationships.map((relationship) => relationship.id)

  return getProductIncluded(data)
    .filter(
      (includedProduct) => productIds.includes(includedProduct.id),
    )
    .map((includedProduct) => getOrderProductMainId(includedProduct.attributes))
}

export const getOrderProductQuantity = (
  product: Product,
  orders: OrderResponse | Order[],
): number | undefined => {
  if (Array.isArray(orders)) {
    return orders
      .flatMap((order) => order['order/product'])
      .filter((orderedProduct) => (
        product['product.id'] === getOrderProductMainId(orderedProduct)
      )).reduce((totalQuantity, current) => (
        totalQuantity + current['order.product.quantity']
      ), 0)
  }

  return (orders
    ? getProductIncluded(orders)
      .filter((ele) => (
        getOrderProductMainId(ele.attributes) === product['product.id']
      ))
    : []).reduce((totalQuantity, current) => (
    totalQuantity + current.attributes['order.product.quantity']
  ), 0)
}
