import { getSalesUnit } from '../../../lib/salesUnit'
import { getProductName } from '../../../lib/text'
import { Product } from '../../../utils/types/Product'

interface SingleProductModel {
  product: Product

  isRouterReady?: boolean

  openSampleRequestModal?: () => void

  openDocumentRequestModal?: () => void
}

class SingleProduct {
  product: SingleProductModel['product']

  isRouterReady?: SingleProductModel['isRouterReady']

  openSampleRequestModal?: SingleProductModel['openSampleRequestModal']

  openDocumentRequestModal?: SingleProductModel['openDocumentRequestModal']

  constructor(model: SingleProductModel) {
    const {
      product,
      isRouterReady,
      openSampleRequestModal,
      openDocumentRequestModal,
    } = model

    this.product = product
    this.isRouterReady = isRouterReady
    this.openSampleRequestModal = openSampleRequestModal
    this.openDocumentRequestModal = openDocumentRequestModal

    this.getProductName = this.getProductName.bind(this)
    this.getSalesUnit = this.getSalesUnit.bind(this)
  }

  getProductName() {
    return getProductName(this.product)
  }

  getSalesUnit() {
    return getSalesUnit(this.product)
  }
}

export default SingleProduct
