import { FC } from 'react'

import {
  DIM_MID_LIGHT_COLOR,
  NEUTRAL_GRAY,
  DARK_GREY_COLOR,
  DIM_MID_COLOR,
} from '../../utils/constants'

interface CustomerTypeHomeIconProps {
  active?: boolean
}

const CustomerTypeHomeIcon: FC<CustomerTypeHomeIconProps> = ({ active = false }) => (
  <svg
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="0.5"
      width="48"
      height="48"
      rx="24"
      fill={active ? DIM_MID_LIGHT_COLOR : NEUTRAL_GRAY}
    />
    <path
      d="M15 21L24 14L33 21V32C33 32.5304 32.7893 33.0391 32.4142 33.4142C32.0391 33.7893 31.5304 34 31 34H17C16.4696 34 15.9609 33.7893 15.5858 33.4142C15.2107 33.0391 15 32.5304 15 32V21Z"
      stroke={active ? DIM_MID_COLOR : DARK_GREY_COLOR}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 34V24H27V34"
      stroke={active ? DIM_MID_COLOR : DARK_GREY_COLOR}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CustomerTypeHomeIcon
