const ShieldIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 0L3.125 1.5625C2.08333 8.85417 2.08333 14.0625 3.125 17.1875C5.20833 21.3542 8.33333 23.9583 12.5 25C16.6667 23.9583 19.7917 21.3542 21.875 17.1875C22.9167 14.0625 22.9167 8.85417 21.875 1.5625L12.5 0ZM12.5 23.3837C9.01002 22.4054 6.39097 20.1694 4.5736 16.5901C3.71197 13.8927 3.67543 9.3069 4.51649 2.91464L12.5 1.58405L20.4835 2.91464C21.3246 9.3069 21.288 13.8927 20.4264 16.5901C18.609 20.1694 15.99 22.4054 12.5 23.3837Z"
      fill="white"
    />
    <path
      d="M16.6949 8.40789C16.8245 8.27066 17.0408 8.26447 17.178 8.39409C17.3 8.5093 17.3185 8.69303 17.23 8.82882L17.1919 8.87726L11.3813 15.0296C11.2594 15.1587 11.0627 15.1708 10.9267 15.0677L10.8788 15.0236L7.80259 11.6056C7.67631 11.4653 7.68768 11.2492 7.82799 11.1229C7.95271 11.0106 8.13733 11.0072 8.26551 11.1063L8.3107 11.1483L11.1383 14.2904L16.6949 8.40789Z"
      fill="white"
      stroke="white"
      strokeWidth="0.8"
    />
  </svg>
)

export default ShieldIcon
