import { FC, RefObject } from 'react'
import { Container, Popover, Whisper } from 'rsuite'
import { useRouter } from 'next/router'

import ProfileIcon from '../../../Icons/ProfileIcon'
import { HOVER_DELAY, MODAL_HASHES } from '../../../../utils/constants'
import ProfileIconPickerContent from './ProfileIconPickerContent'
import useIsUrlHashIncluded from '../../../../services/useIsUrlHashIncluded'
import SignInPickerContent from '../SignInPicker/SignInPickerContent'
import { useAuth } from '../../../../services/useAuth'

import styles from '../../../../styles/ProfileIconPicker.module.less'
import signInPickerStyles from '../../../../styles/SignInPicker.module.less'

interface ProfileIconPickerProps {
  onIconClick: (url: string, shouldRedirect?: boolean) => void
  topHeaderRef?: RefObject<HTMLDivElement>
}

const ProfileIconPicker: FC<ProfileIconPickerProps> = (props) => {
  const { onIconClick, topHeaderRef } = props

  const { asPath } = useRouter()
  const { user } = useAuth()

  const { LOGIN_URL_HASH, REGISTER_URL_HASH, INVITE_URL_HASH } = MODAL_HASHES
  const isLogoutPage = asPath?.includes('logout')
  const isLoginUrlHashIncluded = useIsUrlHashIncluded([
    LOGIN_URL_HASH,
    REGISTER_URL_HASH,
    INVITE_URL_HASH,
  ])

  const shouldShowPopover = !isLogoutPage && !isLoginUrlHashIncluded

  return (
    <Whisper
      trigger="hover"
      enterable
      placement="bottomEnd"
      container={topHeaderRef ? topHeaderRef.current || undefined : undefined}
      delayOpen={HOVER_DELAY}
      speaker={shouldShowPopover ? (
        <Popover className={user ? styles['picker-popover'] : signInPickerStyles['signin-popover']}>
          {user ? <ProfileIconPickerContent /> : <SignInPickerContent />}
        </Popover>
      ) : <div />}
    >
      <Container>
        <ProfileIcon
          className={`cursor-pointer flex-shrink-col-static ${styles['profile-icon']}`}
          onClick={() => onIconClick('/my-account')}
        />
      </Container>
    </Whisper>
  )
}

export default ProfileIconPicker
