import { DiscountProduct, Product } from '../../utils/types/Product'
import { libraryFunctions as priceLibraryFunctions, isDiscount } from './price'
import { getTotalPieceCount } from '../salesUnit'

const { getCustomPrices, getReferencePrice, getDefaultPrice } = priceLibraryFunctions

//  Internal functions related to discounts.
//  Use the usePrice or getPrices getter to access discounts in components

const getProductDiscounts = (product: Product): DiscountProduct[] => {
  const defaultPrice = getDefaultPrice(product.price)
  const fullPrice = Number(defaultPrice?.['price.value']) + Number(defaultPrice?.['price.rebate'])
  const totalPieceCount = getTotalPieceCount(product)
  const customPrices = getCustomPrices(product)
  let discountPrices = product.price.filter((price) => price['price.type'] === 'default' && isDiscount(price))

  if (customPrices.length > 0) {
    discountPrices = discountPrices.filter((price) => price['price.label'].includes('custom_discount'))
  }

  const discountPricesFormat = discountPrices.map((price) => {
    const discountPercentage = Math.round(
      ((Number(fullPrice) - Number(price['price.value'])) / Number(fullPrice)) * 100,
    )
    const referencePrice = getReferencePrice(product, price['price.value'])
    return {
      ...price,
      discountPercentage,
      totalPiece: totalPieceCount * price['price.quantity'],
      referencePrice,
    }
  })
  return discountPricesFormat.sort((priceA, priceB) => priceA['price.quantity'] - priceB['price.quantity'])
}

const getCurrentUpcomingDiscount = (product?: Product, totalQuantity?: number): {
  upcomingDiscount: DiscountProduct | undefined
  currentDiscount: DiscountProduct | undefined
  productDiscounts: DiscountProduct[]
} => {
  if (!product) {
    return { upcomingDiscount: undefined, currentDiscount: undefined, productDiscounts: [] }
  }

  const productDiscounts = getProductDiscounts(product)
  const upcomingDiscount = productDiscounts.find((price) => price['price.quantity'] > Number(totalQuantity))
  const availedDiscounts = productDiscounts.filter((price) => price['price.quantity'] <= Number(totalQuantity))
  const currentDiscount = availedDiscounts.length > 0
    ? availedDiscounts[availedDiscounts.length - 1] : undefined
  return { upcomingDiscount, currentDiscount, productDiscounts }
}

// Remove duplicate bulk discounts
// TODO: Once duplicate issue is fixed from BE, the below function can be omitted
export const getDedupedProductDiscounts = (
  productDiscounts: DiscountProduct[],
): DiscountProduct[] => productDiscounts.reduce((prev: DiscountProduct[], current) => {
  const foundDuplicate = prev.find(
    (item) => item.discountPercentage === current.discountPercentage
        && item['price.costs'] === current['price.costs']
        && item['price.label'] === current['price.label']
        && item['price.quantity'] === current['price.quantity'],
  )

  if (!foundDuplicate) {
    return [...prev, current]
  }

  return prev
}, [])

// Export as internal lib function to prevent accidental usage outside of libs
export const libraryFunctions = {
  getCurrentUpcomingDiscount,
}
