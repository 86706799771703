import TagManager from 'react-gtm-module'

import { Product } from '../types/Product'
import { AnalyticsContext } from '../types/analytics'
import { getBrandName, getProductName } from '../../lib/text'
import { getSupplierName } from '../../lib/supplier'
import { getPrices } from '../../lib/getPrices'

interface SaveProductToggleEventParams {
  product: Product
  productUrl: string
  context: AnalyticsContext
  eventName: 'save_product' | 'unsave_product'
}

const saveProductToggleEvent = (
  { product, productUrl, eventName, context }: SaveProductToggleEventParams,
) => {
  const brandName = getBrandName(product)
  const supplierName = getSupplierName(product.supplier[0])
  const itemName = getProductName(product)
  const { final: { price }, currency } = getPrices(product, 1)

  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      context,
      product_id: product['product.code'],
      product_name: itemName,
      product_brand: brandName || supplierName,
      product_url: productUrl,
      price,
      currency,
      supplier: supplierName,
    },
  })
}

export default saveProductToggleEvent
