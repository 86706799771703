import { FC } from 'react'

import { DIM_MID_COLOR } from '../../utils/constants'

interface ToolIconProps {
  className?: string
  color?: string
}

const ToolIcon: FC<ToolIconProps> = ({ className, color = DIM_MID_COLOR }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7013 7.30364C14.5181 7.49057 14.4155 7.74189 14.4155 8.00364C14.4155 8.26539 14.5181 8.51671 14.7013 8.70364L16.3013 10.3036C16.4882 10.4869 16.7396 10.5895 17.0013 10.5895C17.2631 10.5895 17.5144 10.4869 17.7013 10.3036L21.4713 6.53364C21.9742 7.64483 22.1264 8.88288 21.9078 10.0828C21.6892 11.2827 21.11 12.3875 20.2476 13.2499C19.3852 14.1124 18.2804 14.6915 17.0805 14.9101C15.8806 15.1287 14.6425 14.9765 13.5313 14.4736L6.62132 21.3836C6.2235 21.7815 5.68393 22.005 5.12132 22.005C4.55871 22.005 4.01914 21.7815 3.62132 21.3836C3.2235 20.9858 3 20.4462 3 19.8836C3 19.321 3.2235 18.7815 3.62132 18.3836L10.5313 11.4736C10.0285 10.3625 9.87624 9.12441 10.0949 7.9245C10.3135 6.72459 10.8926 5.6198 11.755 4.75736C12.6175 3.89493 13.7223 3.31581 14.9222 3.09718C16.1221 2.87856 17.3601 3.03081 18.4713 3.53364L14.7113 7.29364L14.7013 7.30364Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ToolIcon
