import { FC } from 'react'
import { Badge, Button, Divider, FlexboxGrid, Nav } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'
import dynamic from 'next/dynamic'
import Link from 'next/link'

import { useAuth } from '../../../services/useAuth'
import useUrls from '../../../services/useUrls'
import useContactModal from '../../Modals/hooks/useContactModal'
import DroppeLogo from '../../Icons/DroppeLogo'
import MobileCountryPicker from './CountryPicker/MobileCountryPicker'
import MobileSearch from './Search/MobileSearch'
import MobileCategoriesMenu from './MobileCategoryTree/MobileCategoriesMenu'
import useInternalTraffic from '../../hooks/useInternalTraffic'
import useLoginModal from '../../../services/useLoginModal'
import HeartIcon from '../../Icons/SavedProducts/HeartIcon'
import useIsOnMobile from '../../../services/useIsOnMobile'
import {
  LOGIN_MODAL_TRIGGERED_PLACE_KEY,
  LoginModalTriggeredPlace,
  ScreenSize,
} from '../../../utils/constants'
import SignInPicker from './SignInPicker/SignInPicker'
import CustomerTypeSwitcher from './CustomerTypeSwitcher'
import ProfileIconPicker from './ProfileIconPicker/ProfileIconPicker'
import MobileNavigationCustomerTypeSwitcher from './MobileNavigationCustomerTypeSwitcher'
import SearchBar from './Search/Search'
import CatalogHeaderIcon from '../../Icons/CatalogHeaderIcon'
import useBuyerCatalogs from '../../../services/useBuyerCatalogs'
import CartNav from './CartNav'
import useSavedProduct from '../../hooks/useSavedProduct'

import styles from '../../../styles/MobileNavigation.module.less'

const InternalTrafficButton = dynamic(() => import('../../InternalTrafficButton'))

const MobileNavigation: FC = () => {
  const { user } = useAuth()
  const { urlT, pushT } = useUrls()
  const { t } = useTranslation('common')

  const { isInternalTraffic } = useInternalTraffic()
  const isOnMobileSm = useIsOnMobile(ScreenSize.sm)
  const isOnMobileMd = useIsOnMobile(ScreenSize.md)

  const openContactModal = useContactModal('main_header_modal', 'main_header_contact_modal')
  const { openLoginModal } = useLoginModal()
  const { orderListsCount } = useBuyerCatalogs()
  const { savedProductsCount } = useSavedProduct()

  const handleIconClick = (url: string, shouldRedirect?: boolean) => {
    if (user) {
      pushT(url)
      return
    }

    // Using session storage to handle redirection logic in LoginModal
    if (shouldRedirect) {
      sessionStorage.setItem(LOGIN_MODAL_TRIGGERED_PLACE_KEY, LoginModalTriggeredPlace.headerHeart)
    }

    openLoginModal('main_header')
  }

  return (
    <Nav className={`max-width-lg ${styles['mobile-navigation-wrapper']}`}>
      <FlexboxGrid className={styles['mobile-top-navigation']}>
        <Link
          className={styles['logo-link-wrapper']}
          href={urlT('/')}
        >
          <DroppeLogo size={`${isOnMobileSm ? 'sd' : 'lm'}`} />
        </Link>

        <div className={styles['right-buttons-wrapper']}>
          <div className={styles['mobile-country-picker']}>
            <MobileCountryPicker />
          </div>

          {!isOnMobileMd && <SignInPicker onIconClick={handleIconClick} />}

          <Button
            appearance="ghost"
            className={`margin-left-spacer-double ${styles['contact-us-button']}`}
            onClick={(e) => {
              e.preventDefault()
              openContactModal()
            }}
          >
            {t('Contact')}
          </Button>

          {!isOnMobileSm && (
            <>
              <Divider
                vertical
                className={styles['vertical-divider']}
              />

              <CustomerTypeSwitcher />
            </>
          )}
        </div>
      </FlexboxGrid>

      <Divider className="margin-top-zero margin-bottom-zero" />

      <FlexboxGrid
        align="middle"
        className={`${styles['mobile-navigation-bottom']} padding-right-spacer-double padding-left-spacer-double`}
      >
        <div className={styles['mobile-navigation-bottom-content']}>
          <div className={styles['mobile-category-menu']}>
            <MobileCategoriesMenu />
          </div>

          <div className={styles['right-items-wrapper']}>
            {isInternalTraffic && (
              <div className={styles['internal-traffic']}>
                <InternalTrafficButton />
              </div>
            )}

            {!isOnMobileSm
              ? (
                <SearchBar />
              )
              : (
                <Button
                  appearance="link"
                  className={`${styles['nav-icon']} ${styles['search-icon']}`}
                >
                  <MobileSearch />
                </Button>
              )}

            <Button
              appearance="link"
              className={`${styles['nav-icon']} ${styles['catalog-icon']}`}
              onClick={() => handleIconClick('/my-catalogs', true)}
            >
              <Badge content={orderListsCount || false}>
                <CatalogHeaderIcon />
              </Badge>
            </Button>

            <Button
              appearance="link"
              className={`${styles['nav-icon']} ${styles['heart-icon']}`}
              onClick={() => handleIconClick('/my-products', true)}
            >
              <Badge content={savedProductsCount || false}>
                <HeartIcon
                  variant="dark"
                />
              </Badge>
            </Button>

            <Button
              appearance="link"
              className={`${styles['nav-icon']} ${styles['profile-icon']}`}
            >
              <ProfileIconPicker
                onIconClick={handleIconClick}
              />
            </Button>

            <Button
              appearance="link"
              className={`${styles['nav-icon']} ${styles['cart-icon']}`}
            >
              <CartNav />
            </Button>
          </div>
        </div>
      </FlexboxGrid>

      {isOnMobileSm && <MobileNavigationCustomerTypeSwitcher />}
    </Nav>
  )
}

export default MobileNavigation
