import { useState, DOMAttributes, useEffect, RefObject } from 'react'

import { debounce } from '../utils/event-utils'

type HandleScroll = DOMAttributes<HTMLDivElement>['onScroll']
type CurrentTarget = EventTarget & HTMLDivElement

/**
 * Check if an div element is scrollable and if the element is already scrolled to Bottom or Top
 * @param scrollRef Reference to the scrollable element
 */
const useHasScrolledToBottom = (scrollRef: RefObject<HTMLDivElement>) => {
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(!scrollRef.current)
  const [hasScrolledToTop, setHasScrolledToTop] = useState(!scrollRef.current)

  const scrollListener = (currentTarget: CurrentTarget) => {
    const { scrollHeight, scrollTop, clientHeight } = currentTarget
    const isAtTheBottom = scrollHeight - scrollTop - clientHeight < 1
    const isAtTop = scrollTop === 0

    setHasScrolledToTop(isAtTop)
    setHasScrolledToBottom(isAtTheBottom)
  }

  // Checking if the element is scrollable after first mount
  useEffect(() => {
    if (scrollRef.current) scrollListener(scrollRef.current)
  }, [])

  const debouncedHandleScroll = debounce(scrollListener, 50)

  // Needs to pass event.currentTarget and not whole event,
  // otherwise event.currentTarget will be null when debounce handles it
  const handleScroll: HandleScroll = (event) => debouncedHandleScroll(event.currentTarget)

  return { handleScroll, hasScrolledToBottom, hasScrolledToTop }
}

export default useHasScrolledToBottom
